import { message } from 'antd';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { API_KEY, Cmf_Base } from '../../Data/Api';
import { openNotificationWithIcon } from '../../utils/Functions';

import { NotificationManager } from 'react-notifications';
import 'sweetalert2/src/sweetalert2.scss';
import history from '../../utils/history';
import { logsOnboarding } from './requestCard.actions';

export const incrementCreditApplied = params => {
  return async (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    dispatch({
      type: 'LOAD_TRANSACTION',
      payload: true,
    });
    try {
      const response = await axios.post(
        `${Cmf_Base}api/Customers/CreateExtraCredit`,
        params,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          title: '¡Felicidades!',
          text:
            'Tu nuevo límite de crédito ya ha sido procesado y está disponible para que lo utilices.',
          icon: 'success',
          confirmButtonColor: '#3DAE2B',
          confirmButtonText: 'Ok',
        }).then(e => {
          // Aquí la alerta se ha cerrado
          if (e.value) {
            window.location.reload(true);
            history.push('/Home');
          }
          dispatch({
            type: 'LOAD_TRANSACTION',
            payload: false,
          });
        });
      }
    } catch (error) {
      dispatch({
        type: 'LOAD_TRANSACTION',
        payload: false,
      });
      openNotificationWithIcon('error', error.message);
    }
  };
};
/**
 * proceso usado para reenviar passkit desde el propio portal cliente
 * @param {*} step  3 "Reenvio"
 * @param {*} customerId "customerid" para obtener el customerOriginId
 * @returns
 */
export const resendPasskit = (step, customerId) => {
  return async (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    dispatch({
      type: 'LOAD_PASSKITSEND',
    });
    try {
      const getCustomOrigin = await axios.get(
        `${Cmf_Base}api/Customers/GetCustomerOriginbyCustomerId?customerId=${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const {data} = getCustomOrigin;
      const response = await axios.get(
        `${Cmf_Base}api/passkit/manageAccountPasskit/${data[0]?.customerOriginId}/${step}`,
        {
          headers: {
            Apikey: API_KEY,
          },
        },
      );
      if (response.data.error === 'false') {
        NotificationManager.success(
          'Tarjeta reenviada exitosamente a tu correo electrónico.',
        );
      } else {
        NotificationManager.error(
          'Ha ocurrido un error al reenviar la tarjeta. Para asistencia escríbenos a servicioalcliente@cmf.com.pa',
        );
      }
    } catch (error) {
      NotificationManager.error(
        'Ha ocurrido un error al reenviar la tarjeta. Para asistencia escríbenos a servicioalcliente@cmf.com.pa',
      );
    } finally {
      dispatch({
        type: 'HIDE_PASSKITSEND',
      });
    }
  };
};

/**
 * Creates extra credit for a customer.
 * @param {Object} params - The parameters for creating extra credit.
 * @param {Function} onSuccess - The function to be called on success.
 * @returns {Function} - The async function that dispatches actions based on the response.
 */
export const createExtraCredit = (params, onSuccess) => {
  return async (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    const userId = CurrentUser?.accountSF[0]?.Documento_de_identidad__c;
    dispatch({
      type: 'LOAD_CREATE_EXTRA_CREDIT',
      payload: true,
    });
    try {
      const response = await axios.post(
        `${Cmf_Base}api/Customers/CreateExtraCredit`,
        params,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      logsOnboarding(
        userId,
        '',
        'CREATED_EXTRACREDIT',
        response?.data?.problemPublic,
        params,
        true,
      );
      dispatch({
        type: 'LOAD_CREATE_EXTRA_CREDIT',
        payload: false,
      });
      if (response.data.complete === true) {
        onSuccess(true);
        await dispatch({
          type: 'EXTRA_CREDIT_COMPLETE',
          payload: true,
        });
        await dispatch({
          type: 'CALCULATOR',
          payload: response.data,
        });
        await dispatch({
          type: 'EXTRA_CREDIT_SUCCESS',
          payload: [],
        });
        await dispatch({
          type: 'EXTRA_CREDIT_COMPLETE',
          payload: true,
        });
      } else {
        NotificationManager.success(
          response?.data?.problemPublic ||
            'Ha ocurrido un error en el proceso.',
        );
      }
    } catch (error) {
      onSuccess(false);
      dispatch({
        type: 'LOAD_CREATE_EXTRA_CREDIT',
        payload: false,
      });
      if (error?.response?.status === 400) {
        NotificationManager.error(error.response?.data?.problemPublic);
      } else {
        NotificationManager.error('Ha ocurrido un error en el proceso.');
      }
      logsOnboarding(
        userId,
        '',
        'CREATED_EXTRACREDIT',
        error?.response?.data,
        params,
        false,
      );
      await dispatch({
        type: 'EXTRA_CREDIT_COMPLETE',
        payload: false,
      });
    }
  };
};

export const getFinancialInstitution = () => {
  return async (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const {CurrentUser} = AuthenticationReducer;
    const token = CurrentUser?.tokenCMF;
    try {
      const data = await axios.get(
        `${Cmf_Base}api/catalogs/FinancialInstitution`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (Array.isArray(data.data) && data.data.length > 0) {
        dispatch({
          type: 'GET_FINANCIAL_INSTITUTION_SUCCESS',
          payload: data.data,
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };
};

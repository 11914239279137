import { styled } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WrapperSide from 'src/components/Aside/Wrapper';
import SubtitleResponsiveOnboarding from 'src/components/TitleInput/SubtitleResponsiveOnboarding';
import TitleResponsiveOnboarding from 'src/components/TitleInput/TitleResponsiveOnboarding';
import { BreakPointTheme } from 'src/theme/breakpoints';
import {
  getConfigOnboard,
  getSettings,
  getSwitchStatusMotor2
} from '../../store/actions/requestCard.actions';
import VerificationForm1 from './DataVerification/VerificationForm1';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
});

const Section = styled('section')({
  background: '#F2F4F7 0% 0% no-repeat padding-box',
  width: '100%',
  minHeight: '100vh !important',
  boxSizing: 'border-box',
  '@media (max-width: 600px)': {
    height: '100% !important',
    minHeight: '100vh !important',
  },
});
const ContainerComponent = styled('div')(({theme}) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '70px',
  [BreakPointTheme.breakpoints.down('sm1')]: {
    paddingTop: '35px',
  },
}));
const ContainerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  margin: 'auto',
  [BreakPointTheme.breakpoints.down('sm1')]: {
    width: 'auto',
    margin: '1rem 1rem 0rem 1rem',
  },
}));

const VerifyMailPhone = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfigOnboard());
    dispatch(getSwitchStatusMotor2());
    dispatch(getSettings());
  }, []);
  
  return (
    <Container>
      <WrapperSide background="linear-gradient(#003a11, #016116, #4fa70a)" />
      <Section>
        <ContainerComponent>
          <ContainerHeader>
            <TitleResponsiveOnboarding
              minSize={40}
              maxSize={70}
              text="Autenticación de datos"
              fontFamily="Red Hat Text"
              color="#011E41"
              style={{
                lineHeight: '2.25rem',
                textAlign: 'start !important',
                '@media (max-width: 600px)': {
                  textAlign: 'center !important',
                },
              }}
              textAlign="start"
            />
            <SubtitleResponsiveOnboarding
              minSize={25}
              maxSize={40}
              text="Antes de comenzar, es necesario verificar tu correo y tu número de
            celular"
              color="#000"
              fontFamily="Red Hat Text"
              style={{
                textAlign: 'start !important',
                '@media (max-width: 600px)': {
                  textAlign: 'center !important',
                },
              }}
            />
          </ContainerHeader>
          <VerificationForm1 props={props} />
        </ContainerComponent>
      </Section>
    </Container>
  );
};

export default VerifyMailPhone;

import { DialogTitle, FormControlLabel, FormGroup } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import WrapperSide from 'src/components/Aside/Wrapper';
import ButtonNextOnboarding from 'src/components/Button/ButtonNextOnboarding';
import FooterOnboar from 'src/components/Footer/FooterOnboarding';
import SkeletonCMF from 'src/components/Loading/skeleton';
import ModalCommon from 'src/components/Modal/ModalCommon';
import ModalGlobal from 'src/components/Modal/ModalGlobal';
import UniversalText from 'src/components/TitleInput/UniversalText';
import CountdownTimer from 'src/functions/CountdownTimer';
import { sendSmsEid, verifySmsEid } from 'src/store/actions/eidServicesAction';
import {
  activateProduct,
  loadPDF,
  loadTermsPDF,
  updateImgOnboardingCurrent,
} from 'src/store/actions/requestCard.actions';
import { BreakPointTheme } from 'src/theme/breakpoints';
import AirplanePaper from '../../assets/images/onboardingCredito/airplanePaper.svg';
import check from '../../assets/images/onboardingCredito/checkMark.svg';
import PDFView from '../../components/PDFView';
import { APP, stamp } from '../../utils/Constants';
import '../CardRequest/styles/form-style.scss';
import '../CardRequest/styles/styleSign.scss';
import ModalProcessing from './Modals/ModalProcessing';
import SCREENS from './screens';
const styles = {
  formItem: {
    margin: 0,
    width: '100%',
  },
  label: {
    fontFamily: 'Red Hat Text',
  },
};

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
}));

const secondsToExpire = 3;

const ButtonUniversal = styled(LoadingButton)(
  ({textColor, backgroundColor}) => ({
    letterSpacing: '0px',
    textTransform: 'none',
    opacity: 1,
    borderRadius: '38px',
    color: textColor || '#FFFFFF',
    width: '274px',
    height: '76px',
    background: backgroundColor
      ? `transparent linear-gradient(270deg, ${backgroundColor} 0%, #399001 100%)`
      : 'transparent linear-gradient(270deg, #53A600 0%, #399001 100%)',
    [BreakPointTheme.breakpoints.down('xsm')]: {
      height: '50px',
    },
    [BreakPointTheme.breakpoints.down('sm')]: {
      height: '55px',
    },
    '&:disabled': {
      background:
        'transparent linear-gradient(270deg, #D0D0D0 0%, #B0B0B0 100%)', // Cambia el color de fondo para el estado deshabilitado
      color: '#A0A0A0', // Cambia el color del texto para el estado deshabilitado
      cursor: 'not-allowed', // Cambia el cursor para indicar que el botón está deshabilitado
      opacity: 0.5, // Ajusta la opacidad si es necesario
    },
  }),
);

const ButtonSign = styled(LoadingButton)(({textColor, backgroundColor}) => ({
  letterSpacing: '0px',
  opacity: 1,
  borderRadius: '38px',
  color: textColor || '#FFFFFF',
  width: '274px',
  height: '76px',
  background: backgroundColor,
  border: '2px solid #53a600',
  [BreakPointTheme.breakpoints.down('xsm')]: {
    height: '50px',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    height: '55px',
  },
}));

const ContentMain = styled('div')(() => ({
  display: ' flex',
  alignItems: ' center',
  flexDirection: ' column',
  width: ' 80%',
  margin: ' auto',
  [BreakPointTheme.breakpoints.down('sm')]: {
    width: ' 90%',
  },
}));
const TypographyTitle = styled('h1')(() => ({
  width: '100%',
  marginTop: '5vh !important',
  color: '#011E41',
  textAlign: 'initial',
  fontFamily: 'Red Hat Text',
  fontSize: 'clamp(25px, 2.5vw, 40px)',
  fontWeight: 'bold',
}));
const AcceptConditionContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '4%',
  paddingBottom: 25,
  [BreakPointTheme.breakpoints.down('lg1')]: {
    // paddingTop: 0,
  },
  [BreakPointTheme.breakpoints.down('lg')]: {
    // paddingBottom: 0,
    flexDirection: 'column',
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
const ColMain = styled(Grid)(() => ({
  alignItems: 'flex-start',
  [BreakPointTheme.breakpoints.down('sm')]: {
    textAlign: 'initial',
    font: 'normal normal normal 1em/31px Nunito',
  },
}));
const ColTerms = styled(Grid)(() => ({
  font: 'normal normal normal 1em/19px Nunito',
  paddingRight: 35,
  [BreakPointTheme.breakpoints.down('lg')]: {
    paddingRight: 0,
    paddingBottom: 25,
  },
  [BreakPointTheme.breakpoints.down('sm')]: {
    font: 'normal normal normal 0.75em/16px Nunito ',
  },
}));
const ModalDiv = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  width: '100%',
  height: '100%',
  marginTop: '1.5rem',
  [BreakPointTheme.breakpoints.down('sm')]: {
    height: '100%',
    paddingTop: '5%',
  },
}));

const ButtonModalGreen = styled(LoadingButton)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '9999px',
  width: '100%',
  color: '#ffffff',
  height: '60px',
  [theme.breakpoints.up('md')]: {
    height: '65px',
  },
}));

const ImageSendIcon = styled('img')(({theme}) => ({
  width: '77px',
  height: '47px',
  marginBottom: '1rem',
  [theme.breakpoints.up('md')]: {
    height: '55px',
    width: '90px',
  },
}));

const getLocation = () => {
  return stamp.locations.default;
};

const SignPDFDefault = props => {
  const [code, setCode] = useState('');
  const [smsVerify, setSmsVerify] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [requestId, setId] = useState(null);
  const [signatureCreated, setValidSignature] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [signDocument, setSignDocument] = useState(null);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [loadingVerificationsign, setLoadingVerifySign] = useState(false);
  const [acceptTermsLetter, setAcceptTermsLetter] = useState(false);
  const [finallyTimer, setFinallyTimer] = useState(false);

  const [empty, setEmpty] = useState(false);
  const [sigWrapperWidth, setSigWrapperWidth] = useState(null);
  const [_UploadDocs, setUploadDocs] = useState(false);
  const [visibleIndication, setVisibleIndication] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [scribble, setScribble] = useState(false);
  const dispatch = useDispatch();
  const {
    form,
    identityId,
    documentId,
    onboardingId,
    documentBase64,
    productId,
    requestVideoId,
    productIsActive,
    LoadingactivePro,
    origin,
    base64Terms,
    loadToPdf,
    signCapture,
  } = useSelector(state => state.RequestCardReducer);

  const sigPad = useRef({});
  useEffect(() => {
    dispatch(loadPDF(onboardingId, origin, 'CTR'));
  }, []);

  const sendSMS = () => {
    setCode('');
    setLoadingVerification(true);
    const payload = {
      documentsId: [documentId],
      identityId: identityId,
      message: {
        phone: '+507' + form.cellphone,
        text:
          'Firme utilizando el siguiente codigo: *|challengeCode|* ,Valido por *|ttl|*.',
        from: APP.companyName,
      },
      challengeCode: {
        charset: 'alphanumeric',
        length: 4,
      },
      stamp: {location: getLocation(origin)},
      ttl: secondsToExpire,
      ttlUnit: 'm',
    };
    dispatch(sendSmsEid(payload))
      .then(response => {
        if (response.data.identity.status === 'VALID') {
          setId(response.data.requestId);
          setOpenModal(true);
          setSmsVerify(true);
          setLoadingVerification(false);
          setVisibleIndication(false);
          setFinallyTimer(false);
        }
      })
      .catch(error => {
        // Maneja el error aquí, tal vez muestra un mensaje de error al usuario
      })
      .finally(() => {
        setLoadingVerification(false);
      });
  };

  const verifySMS = () => {
    setLoadingVerifySign(true);
    const payload = {
      requestId,
      challengeCode: code,
      stamp: {location: getLocation(origin)},
    };
    dispatch(verifySmsEid(payload))
      .then(response => {
        if (response.data.id) {
          setOpenModal(false);
          setValidSignature(true);
          setLoadingVerifySign(false);
          setSignDocument(response.data.documents[0].signedDocument.documentId);
        }
      })
      .catch(error => {})
      .finally(() => {
        setLoadingVerifySign(false);
      });
  };

  const clear = () => {
    sigPad.current.clear();
    setEmpty(true);
  };
  const onCancel = () => {
    setScribble(false);
  };

  const SignatureCanvasIsEmpty = () => {
    const signatureCanvas = sigPad;
    setEmpty(signatureCanvas.current.isEmpty());
  };

  const handleChange = event => {
    const value = event.target.value.toLowerCase();

    if (value.length <= 4 && /^[a-z0-9]*$/.test(value)) {
      setCode(value);
    }
  };

  const sendActivateProduct = async () => {
    setLoadingProduct(true);
    try {
      await dispatch(
        activateProduct(productId, requestVideoId, documentId, signDocument),
      );
    } catch (error) {
      setLoadingProduct(false);
    } finally {
      setLoadingProduct(false);
    }
  };
  function onCancelIntruccion() {
    setVisibleIndication(false);
  }

  function onFinish(success) {
    if (success) {
      setUploadDocs(false);
      setScribble(false);
      setVisibleIndication(true);
    } else if (!success) {
      setUploadDocs(false);
    }
  }

  const renderSignPaint = () => {
    const handleClick = () => {
      setUploadDocs(true);
      dispatch(
        updateImgOnboardingCurrent(
          `${identityId}.sign.jpeg`,
          sigPad.current.getTrimmedCanvas().toDataURL('image/png'),
          onboardingId,
          origin,
          'CTR',
          onFinish.bind(this),
        ),
      );
    };

    return (
      <Dialog
        style={{zIndex: 100}}
        centter
        open={scribble}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}>
        <DialogTitle
          sx={{
            color: '#011E41',
            fontSize: 'clamp(18px, 2.5vw, 25px) !important',
            fontWeight: '600 !important',
          }}>
          Añadir firma digital
          <IconButton
            aria-label="close"
            onClick={() => onCancel()}
            style={{position: 'absolute', right: '8px', top: '8px'}}>
            <CancelIcon
              sx={{
                width: '37px',
                height: '37px',
              }}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            ref={ref => {
              if (
                ref &&
                ref.getBoundingClientRect().width - 2 !== sigWrapperWidth
              ) {
                setSigWrapperWidth(ref.getBoundingClientRect().width - 2);
              }
            }}
            className="sigContainer">
            <SignaturePad
              penColor="black"
              ref={sigPad}
              canvasProps={{
                width: sigWrapperWidth,
                height: 200,
              }}
              onEnd={_e => {
                SignatureCanvasIsEmpty();
              }}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginBottom: '1rem',
            [BreakPointTheme.breakpoints.down('sm')]: {
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexDirection: 'column-reverse',
            },
          }}>
          <ButtonSign
            textColor="#399001"
            backgroundColor="#FFFFFF"
            sx={{
              width: '240px',
              height: '53px',
              opacity: 1,
              borderRadius: '38px',
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '95%',
                height: '60px',
                borderRadius: '30px',
              },
            }}
            onClick={clear}>
            Limpiar
          </ButtonSign>
          <ButtonUniversal
            disabled={empty}
            sx={{
              width: '240px',
              height: '53px',
              opacity: 1,
              borderRadius: '38px',
              [BreakPointTheme.breakpoints.down('sm')]: {
                width: '95%',
                height: '60px',
                marginBottom: '1rem',
                borderRadius: '30px',
              },
            }}
            variant="onboarding"
            loading={signCapture}
            onClick={handleClick}>
            Aceptar
          </ButtonUniversal>
        </DialogActions>
      </Dialog>
    );
  };

  const nextSignEID = () => {
    const handleClick = () => {
      sendSMS();
    };

    return (
      <ModalCommon
        isOpen={visibleIndication}
        onHide={() => onCancelIntruccion()}
        modalClassName={{
          overlay: 'bg-[rgba(0,0,0,0.8)]',
          modal:
            'border border-2 border-gray-300 relative w-full md:w-[70%] lg:w-[989px] h-full md:h-[50%] lg:h-[636px] m-0',
          closeButton: 'hidden',
        }}>
        <div className="w-full flex items-center justify-center flex-col ">
          <div className="w-full  mt-[93px] md:mt-[62px]  flex items-center justify-center flex-col ">
            <img
              src={check}
              className="w-[71.04px] h-[71.04px]"
              alt="checkmark"
            />
            <h1 className="text-[#011E41] font-semibold text-2xl text-[22px] md:text-[30px] text-center mt-[16px] md:px-10">
              Tú firma digital se ha agregado al siguiente documento:
            </h1>
            <ul className="mt-[38px] list-disc">
              <li className="text-lg md:text-[25px]">
                Contrato tarjeta digital CMF
              </li>
              <li className="text-lg md:text-[25px]">
                Carta de descuento directo
              </li>
            </ul>

            <div className="flex w-full mt-[63px] md:mt-[30px]">
              <ButtonModalGreen
                loading={loadingVerification}
                onClick={handleClick}
                style={{background: 'var(--linear-bg)', textTransform: 'none'}}>
                <UniversalText
                  textType="h1"
                  multiline={false}
                  minSize={20}
                  maxSize={25}
                  text="Continua con firma electrónica de tu contrato"
                  color="#FFFFFF"
                  fontFamily="Arial, sans-serif"
                  textAlign="center"
                  style={{margin: '20px'}}
                />
              </ButtonModalGreen>
            </div>
          </div>
        </div>
      </ModalCommon>
    );
  };

  const onChange = (event, activeKey) => {
    setActiveTab(activeKey);
    switch (activeKey) {
      case '1':
        !documentBase64 && dispatch(loadPDF(onboardingId, origin, 'CTR'));
        break;
      case '2':
        !base64Terms && dispatch(loadTermsPDF(onboardingId, origin, 'TYC'));
        break;
    }
  };

  const isButtonDisabled = !(acceptTerms && acceptTermsLetter);

  const handleTimerFinish = () => {
    setFinallyTimer(2);
  };

  return (
    <Container>
      {productIsActive && <Navigate to="/CongratsSign" replace={true} />}
      <ModalGlobal
        isOpen={openModal}
        onHide={() => setOpenModal(false)}
        bodyClassName={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
          '@media (min-width: 768px)': {marginTop: '15px'},
        }}>
        <ModalDiv>
          {smsVerify && (
            <>
              <ImageSendIcon src={AirplanePaper} alt="Airplane" />
              <Box
                sx={{
                  width: '75%',
                  [BreakPointTheme.breakpoints.down('sm')]: {
                    width: '100%',
                  },
                }}>
                <UniversalText
                  textType="h1"
                  multiline={true}
                  minSize={24}
                  maxSize={35}
                  text="Validación de la firma electrónica de tu contrato"
                  color="#011E41"
                  fontFamily="Arial, sans-serif"
                  textAlign="center"
                  style={{fontWeight: '600'}}
                />
                <UniversalText
                  textType="p"
                  multiline={true}
                  minSize={20}
                  maxSize={24}
                  text="Te hemos enviado un código por mensaje de texto a tu número de
                  celular, por favor ingrésalo a continuación"
                  color="#000000"
                  fontFamily="Arial, sans-serif"
                  textAlign="center"
                />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  marginTop: '15px',
                  '@media (min-width: 768px)': {marginTop: '15px'},
                }}
                noValidate
                autoComplete="off">
                <TextField
                  id="outlined-controlled"
                  sx={{
                    paddingLeft: '1.25rem',
                    paddingRight: '1.25rem',
                    borderWidth: '1px',
                    outlineStyle: 'none',
                    width: '100%',
                    height: '70px',
                    borderColor: '#D0D5DD',
                  }}
                  placeholder="Ingresa el código de verificación aquí"
                  value={code}
                  onChange={handleChange}
                  inputProps={{maxLength: 4}} // Restringe la longitud máxima a 4 caracteres
                />
                {finallyTimer === 2 ? (
                  <div className="flex justify-center mt-[10px] md:mt-[10px]">
                    <ButtonUniversal
                      loading={loadingVerification}
                      textColor="#FFFFFF"
                      backgroundColor="#399001"
                      onClick={() => sendSMS()}>
                      Solicita un nuevo código
                    </ButtonUniversal>
                  </div>
                ) : (
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <p
                      style={{
                        textAlign: 'center',
                        color: ['16px', '#026E18'],
                        marginTop: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        '@media (min-width: 768px)': {marginTop: '5px'},
                      }}>
                      El código de verificación expirará en:{' '}
                      <CountdownTimer
                        minutes={3}
                        onFinish={handleTimerFinish}
                      />
                    </p>
                  </div>
                )}
                {finallyTimer !== 2 && (
                  <div className="flex justify-center mt-[10px] md:mt-[10px]">
                    <ButtonUniversal
                      disabled={code.length < 4}
                      loading={loadingVerificationsign}
                      textColor="#FFFFFF"
                      backgroundColor="#399001"
                      onClick={() => verifySMS()}>
                      Aceptar
                    </ButtonUniversal>
                  </div>
                )}
              </Box>
            </>
          )}
        </ModalDiv>
      </ModalGlobal>
      <WrapperSide background="linear-gradient(#003a11, #016116, #4fa70a)" />
      <Wrapper
        style={{flex: 1, height: '100%', width: '100%', overflowX: 'auto'}}>
        <Wrapper style={{alignItems: 'center'}}>
          <ContentMain>
            {_UploadDocs && (
              <ModalProcessing
                isOpen={true}
                onShow={true}
                headingText={'Estamos guardando tu firma.'}
                bodyText={'Aguarda un momento.'}
              />
            )}
            {LoadingactivePro && (
              <ModalProcessing
                isOpen={true}
                onShow={true}
                headingText={'Estamos validando tu firma electrónica.'}
                bodyText={'Aguarda un momento.'}
              />
            )}

            <TypographyTitle textType="subtitleApc" style={{paddingBottom: 25}}>
              Revisa y firma tu contrato y carta de descuento directo
            </TypographyTitle>

            {loadToPdf ? (
              <div className="terms-and-pdf">
                <SkeletonCMF />
                <ModalProcessing
                  isOpen={true}
                  onShow={true}
                  headingText={'Estamos procesando tu contrato'}
                  bodyText={'Aguarda un momento.'}
                />
              </div>
            ) : (
              <Box sx={{width: '100%', height: '60%'}}>
                <Tabs
                  value={activeTab}
                  onChange={onChange}
                  aria-label="document tabs">
                  <Tab
                    label={'Contrato y carta de descuento directo'}
                    value="1"
                    style={{
                      textTransform: 'none',
                    }}
                  />
                  <Tab
                    label="Términos y condiciones"
                    value="2"
                    style={{
                      textTransform: 'none',
                    }}
                  />
                </Tabs>
                {activeTab === '1' && (
                  <Box className="terms-and-pdf">
                    {documentBase64 && <PDFView document={documentBase64} />}
                  </Box>
                )}
                {activeTab === '2' && (
                  <Box className="terms-and-pdf">
                    {base64Terms && <PDFView document={base64Terms} />}
                  </Box>
                )}
              </Box>
            )}
            <AcceptConditionContainer>
              <ColTerms style={{flex: 1.2}}>
                Para poder continuar con su solicitud, es necesario firmar el
                contrato de la tarjeta y la carta de descuento, la cual podrá
                ser utilizada para garantizar el cumplimiento de las
                obligaciones del tarjetahabiente.
              </ColTerms>
              <ColMain
                style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <FormGroup style={styles.formItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!documentBase64}
                        onChange={e => setAcceptTermsLetter(e.target.checked)}
                      />
                    }
                    label={
                      <Typography variant="subtitle1" style={styles.label}>
                        Acepto los Términos y Condiciones
                      </Typography>
                    }
                  />
                </FormGroup>
                <FormGroup style={styles.formItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => setAcceptTerms(e.target.checked)}
                        disabled={!documentBase64}
                      />
                    }
                    label={
                      <Typography variant="subtitle1" style={styles.label}>
                        Acepto firmar la Carta de Descuento
                      </Typography>
                    }
                  />
                </FormGroup>
              </ColMain>
            </AcceptConditionContainer>
          </ContentMain>
        </Wrapper>
        <FooterOnboar
          back={
            <ButtonNextOnboarding
              variant="onboardingOutline"
              data-testid="firmar"
              onClick={() => {
                props.goToStep(SCREENS.CreditApproved);
              }}>
              Volver
            </ButtonNextOnboarding>
          }
          next={
            <>
              {!signatureCreated && (
                <ButtonNextOnboarding
                  variant="onboarding"
                  loading={loadingVerification}
                  disabled={isButtonDisabled}
                  data-testid="firmar"
                  onClick={() => setScribble(true)}>
                  Firmar
                </ButtonNextOnboarding>
              )}
              {signatureCreated && (
                <ButtonNextOnboarding
                  variant="onboarding"
                  loading={loadingProduct}
                  disabled={isButtonDisabled}
                  data-testid="firmar"
                  onClick={() => sendActivateProduct()}>
                  Enviar
                </ButtonNextOnboarding>
              )}
            </>
          }
        />
      </Wrapper>
      {scribble && renderSignPaint()}
      {visibleIndication && nextSignEID()}
    </Container>
  );
};

export default SignPDFDefault;

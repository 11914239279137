import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { axiosInstance } from 'src/config/axiosMiddleware';
import { fieldTypeOptions } from 'src/containers/CardRequest/FormRequest.config';
import { API_KEY, Cmf_Base } from '../../Data/Api';
import {
  ACTIVATE_ONBOARDING,
  ACTIVATE_PRODUCT,
  ACTIVE_PROD_STATUS_HIDE,
  ACTIVE_PROD_STATUS_SHOW,
  ADD_IDENTITY_EID_SUCCESS,
  CHANGE_CURRENT_STEP,
  DATA_SCORE_MATRIX_SUCCESS,
  DIALOG_MESSAGE,
  DOCUMENT_UPLOADED_ERROR,
  DOCUMENT_UPLOADED_SUCCESS,
  EDIT_REQUEST_CARD_FORM,
  FORM_REQUEST_UPDATE,
  GET_CLIENTE_DATA,
  GET_SIGNID_DATA,
  SET_LATIN_DATA_SUCCESS,
  SET_LOAD_SIGN,
} from '../../constrants/reducers';
import {
  APP,
  BRANCH_OFFICES,
  DIALOG_TYPES,
  IDENTITY_TYPES,
  ONBOARDING_STATUS,
  ORIGIN,
  SELLER_ID_TYPE,
  SYSTEM_TYPES,
} from '../../utils/Constants';
import { ConsoleHelper, openNotificationWithIcon } from '../../utils/Functions';
export const headerEID = {
  headers: {
    Authorization: APP.electronicId.tokenAuth,
    'content-type': 'application/json',
  },
};
const header = {
  headers: {
    Apikey: API_KEY,
    'content-type': 'application/json',
  },
};
export const clearOrigin = () => {
  return async dispatch => {
    dispatch({
      type: 'CLEAR_ORIGIN',
    });
  };
};
export const clearData = () => {
  return async dispatch => {
    dispatch({type: CHANGE_CURRENT_STEP, step: 1});
    dispatch({
      type: 'CLEAR_ONBOARING',
    });
  };
};

export const editFormRequest = formFields => {
  ConsoleHelper('FORM----->', formFields);
  return dispatch => {
    dispatch({
      type: EDIT_REQUEST_CARD_FORM,
      payload: formFields,
    });
  };
};
/**
 * Actualiza los datos de un onboarding de credito
 * @param {Array} data
 * @returns
 */
export const PUT_UpdateOnboarding = data => {
  return axiosInstance.put(`api/onboarding`, data);
};
/**
 * Actualiza un onboarding de preaprobado o precalificado
 * @param {Array} data Espera un array con todos los datos guardados en el onboarding de PREAP o PRECA
 * @returns {Array}
 */
export const UpdatePrecaPreaOnboarding = async data => {
  return axiosInstance.post(`api/onboarding/UpdateOnboardingPrePre`, data);
};

/**
 * Permite hacer update de la data de un usaurio en el onboarding de credito
 * @param {*} request payload de data a actualizar
 * @param {*} onCompleted true si paso con exito or false si no
 * @param {*} process nommbre del proceso para guardar logs
 * @returns
 */

export const UpdateOnboardingCredit = async (request, onCompleted, process) => {
  return async (dispatch, getState) => {
    const {KOReducer, RequestCardReducer} = getState();
    const {CurrentOnboarding, acpId} = KOReducer;
    const {
      requestVideoId,
      referido,
      onboardingId,
      branchOfficeId,
      origin,
      identityId,
      form,
    } = RequestCardReducer;
    let payload = {
      id: onboardingId,
      apcId: acpId,
      ...CurrentOnboarding,
      ...request,
      electronicVideoId: requestVideoId,
      branchOfficeId:
        branchOfficeId.length > 3
          ? branchOfficeId
          : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
      statusId: ONBOARDING_STATUS.IN_PROCESS,
      lastModifiedBy: 'CMF',
      ReferralCode: referido,
      electronicSignId: identityId,
      idExpirationDate: form.IDExpirationDate,
    };

    try {
      const response = await axiosInstance.put(`api/onboarding`, payload);
      const {data, status} = await response;
      if (status && status === 200 && data) {
        onCompleted(true);
        dispatch({
          type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
          ob: payload,
        });
        const approvedDates = data?.lastModifiedDate;
        dispatch({
          payload: approvedDates,
          type: 'DATA_CREATED',
        });
      }
    } catch (error) {
      await logsOnboarding(
        onboardingId,
        origin,
        process,
        error.response?.data,
        payload,
        false,
      );
      let problemEnco = JSON.stringify(error?.response?.data);
      let getError = problemEnco.replace(/]|[,$^#@`~*+-/[{};<>]/g, ' ');
      onCompleted(false);
      NotificationManager.error(
        getError ? getError : 'Error actualizando datos',
      );
    }
  };
};

export const CreateLatinData = complete => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();

    const {form, onboardingId, origin} = RequestCardReducer;
    const createdBy = SELLER_ID_TYPE;
    let createLatinData = {
      typeIdentityId: IDENTITY_TYPES.DOCUMENT_ID,
      identityNumber: form.identity,
      createdBy: createdBy,
      latindDataId: 0,
    };

    try {
      const response = await axiosInstance.post(
        `api/onboarding/CreateLatinDataRange`,
        createLatinData,
      );

      const {data} = response;
      complete(true);
      dispatch({
        type: SET_LATIN_DATA_SUCCESS,
        LatinData: data,
      });
    } catch (err) {
      complete(true);
      await logsOnboarding(
        onboardingId,
        origin,
        'CREATE_LATIN_DATA',
        err.response?.data,
        createLatinData,
        false,
      );
    }
  };
};

/**
 * Consulta la matriz de decicion
 * @param {Number} OnboardingId
 * @param {String} CreatedBy
 * @param {String} Origin
 * @returns {Array}
 */
const consultMatrix = async (OnboardingId, CreatedBy, Origin) => {
  return axiosInstance.post(
    `api/onboarding/GenerateScoreMatrixToAprobe/${OnboardingId}/${CreatedBy}/${Origin}`,
    {},
  );
};

const changeStatus = paylodPep => {
  return axiosInstance.post(`api/onboarding/ChangeStatus`, paylodPep);
};
const consultRiskClient = async (id, origin) => {
  return await axiosInstance.post(
    `api/onboarding/OnbEvaluationCustomerRiskLevel?onboarding=${id}&origin=${origin}`,
    {},
  );
};
/**
 *
 * @param {*} payload
 * @returns
 */
const changeStatusPreca = payload => {
  return axiosInstance.post(`api/onboarding/AprobeOnboardingPrePEP`, payload);
};

export const UpdateDataMatrix = (request, onCompleted) => {
  return async (dispatch, getState) => {
    const {KOReducer, RequestCardReducer} = getState();
    const {CurrentOnboarding, acpId} = KOReducer;
    const {
      origin,
      onboardingId,
      onboardingSaved,
      form,
      referido,
    } = RequestCardReducer;
    const email = form.email;
    const nifIdentity = form.identity;
    const nombre = form.firstName;
    const apellido = form.lastName;
    const isFatca =
      form.externalPanamaResident === fieldTypeOptions.YES ? true : false;
    const createdBy = SELLER_ID_TYPE;
    const isPep = request.isPep;
    const isCrs = request.isCrs;
    let updateQuestion;
    let updateService;
    if (origin === ORIGIN.PRECALIFICADO) {
      const payload = {
        action: 'UPD_PRE',
        OnboardingPreDTO: {
          createdBy: SELLER_ID_TYPE,
          lastModifiedBy: 'CMF_AZURE',
          ...onboardingSaved,
          ...request,
          isFatca: isFatca,
          statusId: ONBOARDING_STATUS.IN_PROCESS,
        },
      };
      updateQuestion = payload.OnboardingPreDTO;
      updateService = UpdatePrecaPreaOnboarding(payload);
      changeStatusPreca({
        onboardingPreId: onboardingId,
        commentary: 'Onboarding en proceso',
        statusId: ONBOARDING_STATUS.IN_PROCESS,
        aprobe: false,
        createdBy: SELLER_ID_TYPE,
      });
    } else if (origin === ORIGIN.CREDITO) {
      let DataApcID = {
        ...CurrentOnboarding,
        ...request,
        isFatca: isFatca,
        statusId: ONBOARDING_STATUS.IN_PROCESS,
        ReferralCode: referido,
      };
      updateService = PUT_UpdateOnboarding(DataApcID);
    }
    updateService
      .then(async res => {
        const {data} = res;
        await logsOnboarding(
          onboardingId,
          origin,
          'UPDATE_BEFORE_MATRIX',
          data,
          updateService,
          true,
        );
        consultMatrix(onboardingId, createdBy, origin)
          .then(num => {
            const {data, status} = num;
            if (status && status === 200 && data) {
              dispatch({
                type: DATA_SCORE_MATRIX_SUCCESS,
                matriz: data,
              });
              if (
                (data.result === 'APROBADO' && isPep) ||
                (isCrs && data.result === 'APROBADO') ||
                (data.isPep === true && data.result === 'APROBADO')
              ) {
                if (origin === ORIGIN.PRECALIFICADO) {
                  const payload = {
                    action: 'UPD_PRE',
                    OnboardingPreDTO: {
                      ...updateQuestion,
                      ...onboardingSaved,
                      statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
                      // apcId: acpId,
                      isFatca: isFatca,
                      isPep: true,
                    },
                  };
                  changeStatusPreca({
                    onboardingPreId: onboardingId,
                    commentary: 'Onboarding pendiente de aprobación',
                    statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
                    aprobe: false,
                    createdBy: SELLER_ID_TYPE,
                  });
                  UpdatePrecaPreaOnboarding(payload)
                    .then(async anws => {
                      const res_ = await consultRiskClient(
                        onboardingId,
                        origin,
                      );
                      const levelRisk = JSON.parse(res_.data.jsonAnswer);
                      dispatch({
                        payload: levelRisk.ConsolidatedRisk,
                        type: 'GET_RISK_LEVEL',
                      });
                      onCompleted(true);
                    })
                    .catch(async error => {
                      onCompleted(false);
                      NotificationManager.error('Error actualizando datos');
                    });
                } else if (origin === ORIGIN.CREDITO) {
                  let payloadPep = {
                    ...CurrentOnboarding,
                    ...request,
                    statusId: ONBOARDING_STATUS.IN_PROCESS,
                    apcId: acpId,
                    isFatca: isFatca,
                    isPep: true,
                  };
                  let ChangeStatusPending = {
                    onboardingId: onboardingId,
                    statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
                    commentary: 'Pendiente',
                    createdBy: createdBy,
                    clientEmail: email,
                    clientName: nombre,
                    isPep: isPep,
                    origin: origin,
                  };
                  PUT_UpdateOnboarding(payloadPep)
                    .then(async anws => {
                      const {data} = anws;
                      await logsOnboarding(
                        onboardingId,
                        origin,
                        'UPDATE_PEP',
                        data,
                        payloadPep,
                        true,
                      );
                      changeStatus(ChangeStatusPending)
                        .then(async res => {
                          const res_ = await consultRiskClient(
                            onboardingId,
                            origin,
                          );
                          const levelRisk = JSON.parse(res_.data.jsonAnswer);
                          dispatch({
                            payload: levelRisk.ConsolidatedRisk,
                            type: 'GET_RISK_LEVEL',
                          });
                          onCompleted(true);
                        })
                        .catch(async err => {
                          onCompleted(false);
                          NotificationManager.error('Error cambiando estatus');
                        });
                    })
                    .catch(async error => {
                      await logsOnboarding(
                        onboardingId,
                        origin,
                        'UPDATE_PEP',
                        error.response?.data,
                        payloadPep,
                        false,
                      );
                      onCompleted(false);
                      NotificationManager.error('Error actualizando datos');
                    });
                }
              } else if (
                data.result === 'APROBADO' &&
                data.isPep === false &&
                !isPep &&
                !isCrs
              ) {
                try {
                  /*Pasa a proceso de firmar contrato, no es pep y no es crs */
                  /* Crear Identidad en Electronic ID */
                  const payloadSignatureForm = {
                    email: email,
                    nif: nifIdentity,
                    // phone: phone,
                    primaryName: nombre,
                    secondaryName: apellido,
                  };
                  axios
                    .get(
                      `${APP.electronicId.endpoints.getIdentityId}?nif=${nifIdentity}`,
                      headerEID,
                    )
                    .then(async res => {
                      const {data} = res;

                      dispatch({
                        identityId: data?.id,
                        type: ADD_IDENTITY_EID_SUCCESS,
                      });
                      onCompleted(true);
                    })
                    .catch(async err => {
                      if (APP.isFakeSignature) {
                        /* only for test purporsed */
                        dispatch({
                          identityId: APP.fakeSignature,
                          type: ADD_IDENTITY_EID_SUCCESS,
                        });

                        /* form request info */
                        onCompleted(true);
                      } else {
                        try {
                          const responseIdentity = await axios.post(
                            APP.electronicId.endpoints.onsite,
                            payloadSignatureForm,
                            headerEID,
                          );
                          await logsOnboarding(
                            onboardingId,
                            origin,
                            'CREAR_IDENTIDAD_FIRMA',
                            data,
                            payloadSignatureForm,
                            true,
                          );
                          if (responseIdentity?.data?.id) {
                            dispatch({
                              identityId: responseIdentity?.data?.id,
                              type: ADD_IDENTITY_EID_SUCCESS,
                            });
                          }

                          onCompleted(true);
                        } catch (e) {
                          await logsOnboarding(
                            onboardingId,
                            origin,
                            'CREAR_IDENTIDAD_FIRMA',
                            data,
                            payloadSignatureForm,
                            false,
                          );
                          NotificationManager.error(
                            e?.response?.data?.message
                              ? e?.response?.data?.message
                              : 'Error en creacion de identidad',
                          );
                        }
                      }
                    });
                } catch (e) {
                  const error = e.response;
                  let message = error.data?.message;
                  if (message) {
                    message = error.data?.message;
                  } else if (error?.data?.problemasEncontrados[0]?.error) {
                    message = error?.data?.problemasEncontrados[0]?.error;
                  } else if (error?.data?.problemPublic) {
                    let parse = JSON.parse(error.data.problemPublic);
                    message = parse[0].Error;
                  } else {
                    message = 'No hemos podido completar su solicitud.';
                  }

                  onCompleted(false);
                  NotificationManager.error(message);
                }
                let DataToAprove = {
                  ...request,
                  ...CurrentOnboarding,
                  statusId: ONBOARDING_STATUS.IN_PROCESS,
                  apcId: acpId,
                  isFatca: isFatca,
                };
                if (origin === ORIGIN.CREDITO) {
                  axiosInstance
                    .put(`api/onboarding`, DataToAprove)
                    .then(async res => {
                      const res_ = await consultRiskClient(
                        onboardingId,
                        origin,
                      );
                      const levelRisk = JSON.parse(res_.data.jsonAnswer);

                      if (levelRisk.ConsolidatedRisk === 'ALTO') {
                        let ChangeStatusPendingRisk = {
                          onboardingId: onboardingId,
                          statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
                          commentary: 'Pendiente',
                          createdBy: createdBy,
                          clientEmail: email,
                          clientName: nombre,
                          isPep: false,
                          origin: origin,
                        };
                        changeStatus(ChangeStatusPendingRisk);
                      }
                      dispatch({
                        payload: levelRisk.ConsolidatedRisk,
                        type: 'GET_RISK_LEVEL',
                      });
                      onCompleted(true);
                      const {data} = res;
                      await logsOnboarding(
                        onboardingId,
                        origin,
                        'UPDATE_AFTER_SIGNID',
                        data,
                        DataToAprove,
                        true,
                      );
                      dispatch({
                        type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
                        ob: data,
                      });
                      dispatch({
                        type: 'IN_PROCESS_ONBOARDING',
                        setStatus: ONBOARDING_STATUS.IN_PROCESS,
                      });
                    })
                    .catch(async error => {
                      await logsOnboarding(
                        onboardingId,
                        origin,
                        'UPDATE_AFTER_SIGNID',
                        error.response?.data,
                        DataToAprove,
                        false,
                      );
                    });
                } else if (origin === ORIGIN.PRECALIFICADO) {
                  const payload = {
                    action: 'UPD_PRE',
                    OnboardingPreDTO: {
                      statusId: ONBOARDING_STATUS.IN_PROCESS,
                      ...request,
                      ...onboardingSaved,
                      apcId: acpId,
                      isFatca: isFatca,
                    },
                  };
                  UpdatePrecaPreaOnboarding(payload)
                    .then(async respond => {
                      const res_ = await consultRiskClient(
                        onboardingId,
                        origin,
                      );
                      const levelRisk = JSON.parse(res_.data.jsonAnswer);
                      if (levelRisk.ConsolidatedRisk === 'ALTO') {
                        changeStatusPreca({
                          onboardingPreId: onboardingId,
                          commentary: 'Onboarding pendiente de aprobación',
                          statusId: ONBOARDING_STATUS.PENDING_FOR_APPROVE,
                          aprobe: false,
                          createdBy: SELLER_ID_TYPE,
                        });
                      }
                      dispatch({
                        payload: levelRisk.ConsolidatedRisk,
                        type: 'GET_RISK_LEVEL',
                      });
                      onCompleted(true);
                      dispatch({
                        onboardingSaved: payload.OnboardingPreDTO,
                        type: FORM_REQUEST_UPDATE,
                      });
                      dispatch({
                        type: 'IN_PROCESS_ONBOARDING',
                        setStatus: ONBOARDING_STATUS.IN_PROCESS,
                      });
                    })
                    .catch(err => {
                      ConsoleHelper(err);
                    });
                }
              } else if (data.result === 'RECHAZADO') {
                let rejectStatus;
                if (origin === ORIGIN.CREDITO) {
                  let ChangeStatusReject = {
                    onboardingId: onboardingId,
                    statusId: ONBOARDING_STATUS.REJECTED,
                    commentary: 'Onboarding Rechazado',
                    createdBy: createdBy,
                    clientEmail: email,
                    clientName: nombre,
                    isPep: isPep,
                    origin: origin,
                  };
                  rejectStatus = changeStatus(ChangeStatusReject);
                } else if (origin === ORIGIN.PRECALIFICADO) {
                  let payload = {
                    onboardingPreId: onboardingId,
                    commentary: 'Onboarding Rechazado',
                    statusId: ONBOARDING_STATUS.REJECTED,
                    aprobe: false,
                    createdBy: createdBy,
                  };
                  rejectStatus = changeStatusPreca(payload);
                }
                rejectStatus
                  .then(res => {
                    dispatch({
                      payload: '',
                      type: 'GET_RISK_LEVEL',
                    });
                    onCompleted(true);
                  })
                  .catch(err => {
                    onCompleted(false);
                    NotificationManager.error(
                      'Se ha producido un error al cambiar el estado.',
                    );
                  });
              }
            }
          })
          .catch(error => {
            let mensaje = error?.response?.data;
            onCompleted(false);
            NotificationManager.error(mensaje || 'Ha ocurrido un error!');
          });
        dispatch({
          type: 'UPDATE_STATUS_SUCCESS',
          ob: data,
        });
      })
      .catch(async error => {
        await logsOnboarding(
          onboardingId,
          origin,
          'UPDATE_BEFORE_MATRIX',
          error.response?.data,
          updateService,
          false,
        );
        onCompleted(false);
        NotificationManager.error('Error actualizando datos');
      });
  };
};

export const varaibleChubb = async safe => {
  return async dispatch => {
    dispatch({
      type: 'SET_VARIABLE_CHUBB',
      payload: safe,
    });
  };
};
export const includeSafe = (safe, onComplete) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {onboardingId, origin} = RequestCardReducer;

    let payload = {
      OnboardingId: onboardingId,
      PlanCode: safe,
      Origin: origin,
      CoverageType: 'MO',
      RecordType: 1,
    };
    try {
      const resp = await axios.post(
        APP.cmf02.endpoints.apiChubb,
        payload,
        // { headers: {Apikey: API_KEY} }
      );
      const {data} = resp;
      if (data.Complete === true) {
        onComplete(true);
        dispatch({
          type: 'SET_VARIABLE_CHUBB',
          payload: data.JsonAnswer.InformationInsuranceId,
        });
      } else {
        onComplete(false);
        return dispatch({
          type: DIALOG_MESSAGE,
          dialogType: DIALOG_TYPES.ERROR,
          message: data?.ProblemPublic
            ? data?.ProblemPublic
            : 'Ha ocurrido un error!',
        });
      }
    } catch (error) {
      let mensaje = error?.response?.data?.problemPublic;
      onComplete(false);
      return dispatch({
        type: DIALOG_MESSAGE,
        dialogType: DIALOG_TYPES.ERROR,
        message: mensaje ? mensaje : 'Ha ocurrido un error!',
      });
    }
  };
};
/**
 *
 * @param {*} onboardingId  id del onboarding
 * @param {*} origin  origin del onboarding CREDI,PRECA O PREAP
 * @param {*} commentary Nombre del proceso
 * @param {*} respuestaJson Respuesta de api que se guardara para validar errores
 * @param {*} complete true si fue exitosa o false si no
 * @returns
 */
export const logsOnboarding = async (
  onboardingId,
  origin,
  commentary,
  respuestaJson,
  problem,
  complete,
) => {
  const payload = {
    complete: complete,
    Process: commentary,
    UniqueCode: origin,
    NewCode: onboardingId?.toString(),
    jsonAnswer: JSON.stringify(respuestaJson),
    problemPublic: JSON.stringify(problem),
    createdBy: 'CMF_ONBOARDING',
  };
  return axiosInstance.post('api/onboarding/CreateTransactionAnswer', payload);
};

/**
 * @description
 *  ESTA SECCIÓN CONTENDRÁ TODOS LOS ENDPOINTS QUE SE CONSUMEN CUANDO UN USUARIO ES PEP Y ES APROBADO DESDE EL PORTAL ADMINISTRATIVO
 *  LA PANTALLA QUE SE LE MOSTRARÁ AL USUARIO ES LA DE (ReadAndSignContrato) QUE LE MOSTRARA EL PDF DE CONTRATO, OB Y TÉRMINOS Y CONDICIONES
 * @author Irving_Vasquez
 * @date 6/22/2020
 */
export const updateImgOnboarding = (
  imageName,
  canvasSign,
  id,
  origin,
  template,
  onCompleted,
) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer} = getState();
    const {onboardingGet, branchOfficeId} = RequestCardReducer;
    dispatch({type: SET_LOAD_SIGN, payload: true});
    const base64 = `${canvasSign}`;
    const blob = await (await fetch(base64)).blob();
    const formData = new FormData();
    const _file = new File([blob], imageName);
    formData.append('SourceFile', _file);
    formData.append('FileIdentifier', imageName);
    try {
      const res = await axiosInstance.post(`api/blob/upload`, formData);
      const file_upload = res?.data?.fileUrl;
      if (file_upload) {
        const images = [
          {
            typeImageId: 'A954139C-8D35-4C81-B475-54C41BB974C7',
            description: 'Firma',
            link: file_upload,
            thumbnails: null,
            createdBy: onboardingGet.sellerId,
          },
          ...onboardingGet.images,
        ];
        if (origin === ORIGIN.CREDITO) {
          const payload = {
            ...onboardingGet,
            images,
          };
          const response = await PUT_UpdateOnboarding(payload);
          dispatch({
            payload: response.data,
            type: GET_CLIENTE_DATA,
          });
          dispatch({type: 'SET_DATA_IMG', payload: file_upload});
          const _payload = {
            onboardingId: id,
            templateName: template,
            origin: origin,
            branchOfficeId:
              branchOfficeId.length > 1
                ? branchOfficeId
                : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
          };
          try {
            const _response = await axiosInstance.post(
              'api/print/getOnlyContract',
              _payload,
            );
            if (_response.status === 200) {
              const payloadEID = {
                name: 'documentFile.pdf',
                document: _response.data.response,
              };
              const respon = await axios.post(
                APP.electronicId.endpoints.uploadDocument,
                payloadEID,
                headerEID,
              );
              if (respon?.data?.documentId) {
                await logsOnboarding(
                  id,
                  origin,
                  'SIGID_LOAD_DOCUMENT_ID',
                  response?.data,
                  'documentFile.pdf',
                  true,
                );
                onCompleted(true);
                dispatch({
                  documentId: respon.data.documentId,
                  type: DOCUMENT_UPLOADED_SUCCESS,
                });
              }
            }
          } catch (e) {
            onCompleted(false);
            await logsOnboarding(
              id,
              origin,
              'SIGID_LOAD_DOCUMENT_ID',
              e?.response?.data,
              'documentFile.pdf',
              false,
            );
            openNotificationWithIcon(
              'error',
              e?.response?.data?.message
                ? e?.response?.data?.message
                : 'Ha ocurrido un error al subir documento',
            );
            dispatch({
              type: DOCUMENT_UPLOADED_ERROR,
            });
          }
        } else if (
          origin === ORIGIN.PREAPROBADO ||
          origin === ORIGIN.PRECALIFICADO
        ) {
          const payload = {
            action: 'UPD_PRE',
            OnboardingPreDTO: {
              ...onboardingGet,
              images,
              id: id,
            },
          };
          await axiosInstance.post(
            'api/onboarding/UpdateOnboardingPrePre',
            payload,
          );
          dispatch({type: 'SET_DATA_IMG', payload: file_upload});
          dispatch({
            onboardingSaved: payload.OnboardingPreDTO,
            type: FORM_REQUEST_UPDATE,
          });
          const _payload = {
            onboardingId: id,
            templateName: template,
            origin: origin,
            branchOfficeId:
              branchOfficeId.length > 1
                ? branchOfficeId
                : BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
          };
          try {
            const _response = await axiosInstance.post(
              'api/print/getOnlyContract',
              _payload,
            );
            if (_response.status === 200) {
              const payloadEID = {
                name: 'documentFile.pdf',
                document: _response.data.response,
              };
              const response = await axios.post(
                APP.electronicId.endpoints.uploadDocument,
                payloadEID,
                headerEID,
              );
              if (response?.data?.documentId) {
                await logsOnboarding(
                  id,
                  origin,
                  'SIGID_LOAD_DOCUMENT_ID',
                  response?.data,
                  'documentFile.pdf',
                  true,
                );
                onCompleted(true);
                changeStatusPreca({
                  onboardingPreId: id,
                  commentary: 'Onboarding activo',
                  statusId: ONBOARDING_STATUS.ACTIVE,
                  aprobe: false,
                  createdBy: SELLER_ID_TYPE,
                });
                dispatch({
                  documentId: response.data.documentId,
                  type: DOCUMENT_UPLOADED_SUCCESS,
                });
              }
            }
          } catch (e) {
            await logsOnboarding(
              id,
              origin,
              'SIGID_LOAD_DOCUMENT_ID',
              e?.response?.data,
              'documentFile.pdf',
              false,
            );
            openNotificationWithIcon(
              'error',
              'Ha ocurrido un error al subir documento',
            );
            dispatch({
              type: DOCUMENT_UPLOADED_ERROR,
            });
          }
        }
        return file_upload;
      } else {
        return false;
      }
    } catch (error) {
      onCompleted(false);
      openNotificationWithIcon(
        'error',
        'Ha ocurrido un error al subir documento',
      );
    } finally {
      dispatch({type: SET_LOAD_SIGN, payload: false});
    }
  };
};

/**
 * ( ͡👁 👅 ͡👁)✌
 * @author Irving Vasquez
 * @description: Funcion de traer los datos de un usuario aprobado ya sea CREDI o PREAP
 * @date 2022-06-20
 */
export const datosCliente = (numero, origin, token) => {
  return async dispatch => {
    const originUser = origin.toUpperCase();
    try {
      const response = await axios.get(
        `${APP.cmf02.endpoints.apiChubb}/${numero}/${origin}`,
        {headers: {'Access-Control-Allow-Origin': '*'}},
      );
      const {data, status} = response;
      if (status === 200) {
        dispatch({
          type: 'SET_VARIABLE_CHUBB',
          payload: data.InformationInsuranceId,
        });
      }
    } catch (err) {}

    try {
      let response;

      if (
        originUser === ORIGIN.PREAPROBADO ||
        originUser === ORIGIN.PRECALIFICADO
      ) {
        response = await axios.get(
          `${Cmf_Base}api/onboarding/GetOnboardingPrebyId?onboardingId=${numero}`,
          {headers: {'x-ping': token}},
        );

        let test = response.data;

        function clean(obj) {
          for (let propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
              delete obj[propName];
            }
          }
        }

        clean(test);

        dispatch({
          originSign: originUser,
          payload: test,
          type: GET_CLIENTE_DATA,
        });
      } else if (originUser === ORIGIN.CREDITO) {
        response = await axios.get(`${Cmf_Base}api/onboarding/${numero}`, {
          headers: {'x-ping': token},
        });

        dispatch({
          originSign: 'CREDI',
          payload: response.data,
          type: GET_CLIENTE_DATA,
        });

        dispatch({
          type: 'IN_PROCESS_ONBOARDING',
          setStatus: response.data.statusId,
        });
      } else if (originUser === ORIGIN.PREPAGO) {
        response = await axios.post(
          `${Cmf_Base}api/OnboardingPrepaid/getByIdOrIdentityNumber`,
          {Id: numero, IdentityNumber: ''},
          {headers: {'x-ping': token}},
        );

        dispatch({
          originSign: ORIGIN.PREPAGO,
          payload: response?.data?.jsonAnswer,
          type: GET_CLIENTE_DATA,
        });

        dispatch({
          type: 'IN_PROCESS_ONBOARDING',
          setStatus: response?.data?.jsonAnswer?.statusId,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      openNotificationWithIcon('error', error.message);
      logsOnboarding(
        numero,
        origin,
        'ERROR_GET_CLIENTE_DATA',
        error,
        error.message,
        false,
      );
      // Puedes agregar más lógica para manejar el error aquí, como mostrar un mensaje al usuario o dispatch un error action.
    }
  };
};

/**
 * ( ͡👁 👅 ͡👁)✌
 * @author Irving Vasquez
 * @description: Funcion para obtiene el id de un usuario si existe en signatureID
 * @date 2022-06-20
 */
export const createSign = (numero, origin, token) => {
  return async dispatch => {
    if (origin === ORIGIN.PREAPROBADO || origin === ORIGIN.PRECALIFICADO) {
      axios
        .get(
          `${Cmf_Base}api/onboarding/GetOnboardingPrebyId?onboardingId=${numero}`,
          {headers: {'x-ping': token}},
        )
        .then(res => {
          const nif = res.data.identityNumber;
          const payloadSignatureForm = {
            email: res.data.emails[0]?.email,
            nif: res.data.identityNumber,
            phone: res.data.phones[0].phone,
            primaryName: res.data.firstName,
            secondaryName: res.data.firstSurname,
          };
          axios
            .get(
              `${APP.electronicId.endpoints.getIdentityId}?nif=${nif}`,
              headerEID,
            )
            .then(res => {
              const {data} = res;
              dispatch({
                singIdData: data.id,
                type: GET_SIGNID_DATA,
              });
            })
            .catch(async err => {
              try {
                const res = await axios.post(
                  APP.electronicId.endpoints.onsite,
                  payloadSignatureForm,
                  headerEID,
                );

                return dispatch({
                  singIdData: res.data.id,
                  type: GET_SIGNID_DATA,
                });
              } catch (e) {
                const error = e.response.data.message;

                dispatch({
                  type: DIALOG_MESSAGE,
                  dialogType: DIALOG_TYPES.ERROR,
                  message: error,
                });
              }
            });
          dispatch({
            payload: res.data,
            type: GET_CLIENTE_DATA,
          });
        })
        .catch(err => {
          dispatch({
            type: DIALOG_MESSAGE,
            dialogType: DIALOG_TYPES.ERROR,
            message: 'No se encuentra el onboarding preaprobado.',
          });
        });
    } else if (origin === ORIGIN.CREDITO) {
      axiosInstance
        .get(`${Cmf_Base}api/onboarding/${numero}`, {
          headers: {'x-ping': token},
        })
        .then(res => {
          const nif = res.data.identityNumber;
          const payloadSignatureForm = {
            email: res.data.emails[0]?.email,
            nif: res.data.identityNumber,
            phone: res.data.phones[0].phone,
            primaryName: res.data.firstName,
            secondaryName: res.data.firstSurname,
          };
          axios
            .get(
              `${APP.electronicId.endpoints.getIdentityId}?nif=${nif}`,
              headerEID,
            )
            .then(async _res => {
              const {data} = _res;
              await logsOnboarding(
                numero,
                origin,
                'CREAR_IDENTIDAD_FIRMA',
                data,
                nif,
                true,
              );
              dispatch({
                singIdData: data.id,
                type: GET_SIGNID_DATA,
              });
            })
            .catch(async err => {
              try {
                const res = await axios.post(
                  APP.electronicId.endpoints.onsite,
                  payloadSignatureForm,
                  headerEID,
                );
                await logsOnboarding(
                  numero,
                  origin,
                  'CREAR_IDENTIDAD_FIRMA',
                  err?.data,
                  payloadSignatureForm,
                  true,
                );
                return dispatch({
                  singIdData: res.data.id,
                  type: GET_SIGNID_DATA,
                });
              } catch (e) {
                await logsOnboarding(
                  numero,
                  origin,
                  'CREAR_IDENTIDAD_FIRMA',
                  e?.response?.data,
                  payloadSignatureForm,
                  false,
                );
                const error = e.response.data.message;
                dispatch({
                  type: DIALOG_MESSAGE,
                  dialogType: DIALOG_TYPES.ERROR,
                  message: error,
                });
              }
            });
          dispatch({
            payload: res.data,
            type: GET_CLIENTE_DATA,
          });
        })
        .catch(err => {
          dispatch({
            type: DIALOG_MESSAGE,
            dialogType: DIALOG_TYPES.ERROR,
            message: 'No se encuentra el onboarding de crédito.',
          });
        });
    } else if (origin === ORIGIN.PREPAGO) {
      try {
        const responses = await axios.post(
          `${Cmf_Base}api/OnboardingPrepaid/getByIdOrIdentityNumber`,
          {Id: numero, IdentityNumber: ''},
          {headers: {'x-ping': token}},
        );

        const nif = responses?.data?.jsonAnswer?.identityNumber;
        const payloadSignatureForm = {
          email: responses?.data?.jsonAnswer?.emails[0]?.email,
          nif: responses?.data?.jsonAnswer?.identityNumber,
          phone: responses?.data?.jsonAnswer?.phones[0].phone,
          primaryName: responses?.data?.jsonAnswer?.firstName,
          secondaryName: responses?.data?.jsonAnswer?.firstSurname,
        };

        try {
          // Realiza la segunda solicitud GET
          const _res = await axios.get(
            `${APP.electronicId.endpoints.getIdentityId}?nif=${nif}`,
            headerEID,
          );

          const {data} = _res;

          await logsOnboarding(
            numero,
            origin,
            'CREAR_IDENTIDAD_FIRMA',
            data,
            nif,
            true,
          );

          dispatch({
            singIdData: data.id,
            type: GET_SIGNID_DATA,
          });
        } catch (err) {
          try {
            // Si falla la segunda solicitud GET, realiza una solicitud POST
            const res = await axios.post(
              APP.electronicId.endpoints.onsite,
              payloadSignatureForm,
              headerEID,
            );

            await logsOnboarding(
              numero,
              origin,
              'CREAR_IDENTIDAD_FIRMA',
              err?.data,
              payloadSignatureForm,
              true,
            );

            dispatch({
              singIdData: res.data.id,
              type: GET_SIGNID_DATA,
            });
          } catch (e) {
            await logsOnboarding(
              numero,
              origin,
              'CREAR_IDENTIDAD_FIRMA',
              e?.response?.data,
              payloadSignatureForm,
              false,
            );

            const error = e.response.data.message;
            dispatch({
              type: DIALOG_MESSAGE,
              dialogType: DIALOG_TYPES.ERROR,
              message: error,
            });
          }
        }

        // dispatch({
        //   payload: res.data,
        //   type: GET_CLIENTE_DATA,
        // });
      } catch (err) {
        dispatch({
          type: DIALOG_MESSAGE,
          dialogType: DIALOG_TYPES.ERROR,
          message: 'No se encuentra el onboarding de crédito.',
        });
        logsOnboarding(
          numero,
          origin,
          'ERROR_CREATE_SIGN',
          err,
          err.message,
          false,
        );
      }
    }
  };
};
const ActivarMAtrixOnboarding = async payload => {
  return axiosInstance.post(`api/onboarding/ActiveOnboardingMatrix`, payload);
};
const consultCustomerProduct = async (id, origin) => {
  return axiosInstance.get(
    `api/Customers/GetCustomerOriginbyOnboardingIdandOrigin?onboardingId=${id}&origin=${origin}`,
  );
};
/**
 * Activa el producto y tarjeta de todos los origenes
 */
const ActivarActionProduct = async payload => {
  return axiosInstance.post(`api/Customers/ActiveProductCredTDC`, payload);
};
const UpdateOnboardingCreditPep = async params => {
  return axiosInstance.put(`api/onboarding`, params);
};
export const ActiveProductPep = (
  documentId,
  onboardingNumber,
  originOnboarding,
  signDocument,
  onComplete,
) => {
  return async (dispatch, getState) => {
    const {RequestCardReducer, KOReducer} = getState();
    const {
      onboardingGet,
      statusOnboarindSucces,
      onboardingSaved,
      productId,
    } = RequestCardReducer;
    const {planChubb} = KOReducer;
    if (
      originOnboarding === ORIGIN.PREAPROBADO ||
      originOnboarding === ORIGIN.PRECALIFICADO
    ) {
      if (productId === null) {
        let jsonAnsw;
        const payloadUpdate = {
          action: 'SIG_PRE',
          OnboardingPreDTO: {
            ...onboardingSaved,
            OnboardingPreId: onboardingNumber,
            id: onboardingNumber,
            isSignatureConfirm: true,
            electronicVideoId: onboardingGet.electronicVideoId,
            electronicDocumentId: documentId,
            electronicSignId: signDocument,
            createdBy: onboardingGet.sellerId,
            lastModifiedBy: 'CMF_AZURE',
            lastModifiedDate: new Date(),
            statusId: ONBOARDING_STATUS.IN_PROCESS,
          },
        };
        try {
          await axiosInstance.post(
            `api/onboarding/UpdateOnboardingPrePre`,
            payloadUpdate,
          );
          try {
            const res = await axiosInstance.post(
              `api/onboarding/ActiveOnboardingPre`,
              {
                action: 'ACT_PRE',
                CallActiveOnboardingPreDTO: {
                  OnboardingPreId: onboardingNumber,
                  systemId: SYSTEM_TYPES.CMF,
                  approvedBy: SELLER_ID_TYPE,
                  statusId: ONBOARDING_STATUS.ACTIVE,
                  approvedDate: new Date(),
                },
              },
            );
            const {data} = res;
            if (data) {
              jsonAnsw = JSON.parse(res?.data?.jsonAnswer);
              const onboardingPreAObject = JSON.parse(res?.data?.jsonAnswer);
              dispatch({
                productId: onboardingPreAObject.CustomerProductId,
                customerId: onboardingPreAObject.CustomerId,
                statusOnboarding: ONBOARDING_STATUS.APPROVED,
                type: ACTIVATE_ONBOARDING,
              });
              const payload = {
                customerProductId: jsonAnsw.CustomerProductId,
                activeBy: SELLER_ID_TYPE,
                branchOfficeActiveId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
              };
              dispatch({type: 'ACTIVE_PROD_STATUS_SHOW'});
              try {
                const respon = await axiosInstance.post(
                  `api/Customers/ActiveProductCredTDC`,
                  payload,
                );
                if (respon.data.complete) {
                  dispatch({
                    type: ACTIVATE_PRODUCT,
                  });
                  if (planChubb !== null) {
                    const payload = {
                      OnboardingId: onboardingNumber,
                      PlanCode: 4,
                      CoverageType: 'MO',
                      Origin: originOnboarding,

                      RecordType: 1,
                    };
                    axios
                      .put(
                        `${APP.cmf02.endpoints.apiChubb}/${planChubb}`,
                        payload,
                        // { headers: {Apikey: API_KEY} }
                      )
                      .then(async res => {
                        dispatch({
                          type: ACTIVATE_PRODUCT,
                        });
                      })
                      .catch(err => {
                        dispatch({
                          type: ACTIVATE_PRODUCT,
                        });
                        dispatch({
                          type: DIALOG_MESSAGE,
                          dialogType: DIALOG_TYPES.ERROR,
                          message: err?.response?.data?.problemPublic
                            ? err?.response?.data?.problemPublic
                            : 'Error en proceso de seguro',
                        });
                      });
                  } else if (planChubb === null) {
                    dispatch({
                      type: ACTIVATE_PRODUCT,
                    });
                  }
                } else {
                  dispatch({
                    type: DIALOG_MESSAGE,
                    dialogType: DIALOG_TYPES.ERROR,
                    message:
                      respon?.data?.problemPublic ||
                      'Ha ocurrido un error al activar producto',
                  });
                }
              } catch (error) {
                dispatch({
                  type: DIALOG_MESSAGE,
                  dialogType: DIALOG_TYPES.ERROR,
                  message: error?.response?.data?.problemPublic
                    ? error?.response?.data?.problemPublic
                    : 'Error activando producto',
                });
              } finally {
                dispatch({type: 'ACTIVE_PROD_STATUS_HIDE'});
              }
            }
          } catch (err) {
            const error = err.response;
            let errorMsg = error?.data;
            if (error?.data?.problemPublic) {
              let parse = JSON.parse(error.data.problemPublic);
              errorMsg = parse[0].Error;
            } else {
              errorMsg = 'Error actualizando producto.';
            }
            return dispatch({
              type: DIALOG_MESSAGE,
              title: 'Error enviando solicitud',
              dialogType: DIALOG_TYPES.ERROR,
              message: errorMsg,
            });
          }
        } catch (err) {
          const error = err.response;
          let message = error.data?.message;
          if (error?.data?.problemPublic) {
            let parse = JSON.parse(error.data.problemPublic);
            message = parse[0].Error;
          } else if (error?.data?.problemasEncontrados) {
            message = error?.data?.problemasEncontrados[0]?.error;
          } else {
            message = 'Error actualizando producto.';
          }
          dispatch({
            type: DIALOG_MESSAGE,
            dialogType: DIALOG_TYPES.ERROR,
            message: message,
          });
        } finally {
          dispatch({type: 'ACTIVE_PROD_STATUS_HIDE'});
        }
      } else {
        const payload = {
          customerProductId: productId,
          activeBy: SELLER_ID_TYPE,
          branchOfficeActiveId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
        };
        dispatch({type: 'ACTIVE_PROD_STATUS_SHOW'});
        try {
          const respon = await axiosInstance.post(
            `api/Customers/ActiveProductCredTDC`,
            payload,
          );
          if (respon.data.complete) {
            dispatch({
              type: ACTIVATE_PRODUCT,
            });
            if (planChubb !== null) {
              const payload = {
                OnboardingId: onboardingNumber,
                PlanCode: 4,
                CoverageType: 'MO',
                Origin: originOnboarding,

                RecordType: 1,
              };
              axios
                .put(
                  `${APP.cmf02.endpoints.apiChubb}/${planChubb}`,
                  payload,
                  // { headers: {Apikey: API_KEY} }
                )
                .then(async res => {
                  dispatch({
                    type: ACTIVATE_PRODUCT,
                  });
                })
                .catch(err => {
                  dispatch({
                    type: ACTIVATE_PRODUCT,
                  });
                  dispatch({
                    type: DIALOG_MESSAGE,
                    dialogType: DIALOG_TYPES.ERROR,
                    message: err?.response?.data?.problemPublic
                      ? err?.response?.data?.problemPublic
                      : 'Error en proceso de seguro',
                  });
                });
            } else if (planChubb === null) {
              dispatch({
                type: ACTIVATE_PRODUCT,
              });
            }
          } else {
            dispatch({
              type: DIALOG_MESSAGE,
              dialogType: DIALOG_TYPES.ERROR,
              message:
                respon?.data?.problemPublic ||
                'Ha ocurrido un error al activar producto',
            });
          }
        } catch (error) {
          dispatch({
            type: DIALOG_MESSAGE,
            dialogType: DIALOG_TYPES.ERROR,
            message: error?.response?.data?.problemPublic
              ? error?.response?.data?.problemPublic
              : 'Error activando producto',
          });
        } finally {
          dispatch({type: 'ACTIVE_PROD_STATUS_HIDE'});
        }
      }
    } else {
      // se tienen que hacer las mismas validaciones que se hace en el onboading normal
      if (statusOnboarindSucces.toUpperCase() !== ONBOARDING_STATUS.ACTIVE) {
        const payloadUpdate = {
          ...onboardingGet,
          isSignatureConfirm: true,
          electronicDocumentId: documentId,
          electronicSignId: signDocument,
          createdBy: onboardingGet.sellerId,
          lastModifiedBy: 'CMF_AZURE',
        };
        await UpdateOnboardingCreditPep(payloadUpdate)
          .then(async response => {
            const {data, status} = response;
            if (status && status === 200 && data) {
              dispatch({
                type: 'CREATE_UPDATE_ONBOARDING_SUCCES',
                ob: data,
              });
              await axiosInstance
                .get(
                  `api/onboarding/GetMotorDecisionByOnboardingId?onboardingId=${onboardingNumber}`,
                )
                .then(async res => {
                  let DataToSendActive = {
                    onboardingId: onboardingNumber,
                    customerId: onboardingGet.customerId
                      ? onboardingGet.customerId
                      : null,
                    statusId: ONBOARDING_STATUS.ACTIVE,
                    branchOfficeId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
                    sellerId: onboardingGet.sellerId,
                    systemId: onboardingGet.systemId,
                    approvedBy: onboardingGet.sellerId,
                    approvedDate: new Date(),
                    onboardingMotordecisionId: res.data[0].id,
                  };
                  await ActivarMAtrixOnboarding(DataToSendActive)
                    .then(async res => {
                      const {data} = res;
                      let dat = JSON.parse(data.jsonAnswer);
                      dispatch({
                        type: 'ACTIVE_ONBOARDING_SUCCES',
                        ob: data,
                      });
                      dispatch({
                        type: 'IN_PROCESS_ONBOARDING',
                        setStatus: dat.StatusId,
                      });
                      await consultCustomerProduct(onboardingNumber, 'CREDI')
                        .then(async res => {
                          const {data, status} = res;
                          if (status === 200) {
                            let ActiveDataToSendProduct = {
                              customerProductId: data.customerProductId,
                              activeBy: onboardingGet.sellerId,
                              branchOfficeActiveId:
                                BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
                            };
                            dispatch({type: ACTIVE_PROD_STATUS_SHOW});
                            ActivarActionProduct(ActiveDataToSendProduct)
                              .then(res => {
                                if (res.data.complete) {
                                  dispatch({
                                    type: ACTIVATE_PRODUCT,
                                  });
                                  if (planChubb !== null) {
                                    const payload = {
                                      OnboardingId: onboardingNumber,
                                      PlanCode: 4,
                                      CoverageType: 'MO',
                                      Origin: originOnboarding,
                                      RecordType: 1,
                                    };
                                    axios
                                      .put(
                                        `${APP.cmf02.endpoints.apiChubb}/${planChubb}`,
                                        payload,
                                        // { headers: {Apikey: API_KEY} }
                                      )
                                      .then(async res => {
                                        dispatch({
                                          type: ACTIVATE_PRODUCT,
                                        });
                                      })
                                      .catch(err => {
                                        dispatch({
                                          type: ACTIVATE_PRODUCT,
                                        });
                                        dispatch({
                                          type: DIALOG_MESSAGE,
                                          dialogType: DIALOG_TYPES.ERROR,
                                          message: err?.response?.data
                                            ?.problemPublic
                                            ? err?.response?.data?.problemPublic
                                            : 'Error en proceso de seguro',
                                        });
                                      });
                                  } else if (planChubb === null) {
                                    dispatch({
                                      type: ACTIVATE_PRODUCT,
                                    });
                                  }
                                } else {
                                  dispatch({
                                    type: DIALOG_MESSAGE,
                                    dialogType: DIALOG_TYPES.ERROR,
                                    message:
                                      res?.data?.problemPublic ||
                                      'Ha ocurrido un error al activar producto',
                                  });
                                }
                              })
                              .catch(err => {
                                dispatch({
                                  type: DIALOG_MESSAGE,
                                  dialogType: DIALOG_TYPES.ERROR,
                                  message: err?.response?.data?.problemPublic
                                    ? err?.response?.data?.problemPublic
                                    : 'Error activando producto',
                                });
                              })
                              .finally(() =>
                                dispatch({type: ACTIVE_PROD_STATUS_HIDE}),
                              );
                          }
                        })
                        .catch(err => {
                          dispatch({
                            type: DIALOG_MESSAGE,
                            dialogType: DIALOG_TYPES.ERROR,
                            message: 'Error obteniendo datos de onboarding',
                          });
                        });
                    })
                    .catch(error => {
                      let mens = error?.response?.data?.Message;
                      dispatch({
                        type: DIALOG_MESSAGE,
                        dialogType: DIALOG_TYPES.ERROR,
                        message: mens ? mens : 'Error activando producto.',
                      });
                    });
                })
                .catch(err => {
                  dispatch({
                    type: DIALOG_MESSAGE,
                    dialogType: DIALOG_TYPES.ERROR,
                    message: 'Error obteniendo datos de onboarding.',
                  });
                });
            }
          })
          .catch(() => {
            dispatch({
              type: DIALOG_MESSAGE,
              dialogType: DIALOG_TYPES.ERROR,
              message: 'Ha ocurrido un error al actualizar el onboarding',
            });
          });
      } else {
        await consultCustomerProduct(onboardingNumber, 'CREDI')
          .then(async res => {
            const {data, status} = res;
            if (status === 200) {
              let ActiveDataToSendProduct = {
                customerProductId: data.customerProductId,
                activeBy: onboardingGet.sellerId,
                branchOfficeActiveId: BRANCH_OFFICES.DORADO_TUMBA_MUERTO,
              };
              dispatch({type: ACTIVE_PROD_STATUS_SHOW});
              ActivarActionProduct(ActiveDataToSendProduct)
                .then(res => {
                  if (res.data.complete) {
                    dispatch({
                      type: ACTIVATE_PRODUCT,
                    });
                    if (planChubb !== null) {
                      const payload = {
                        OnboardingId: onboardingNumber,
                        PlanCode: 4,
                        CoverageType: 'MO',
                        Origin: originOnboarding,
                        RecordType: 1,
                      };
                      axios
                        .put(
                          `${APP.cmf02.endpoints.apiChubb}/${planChubb}`,
                          payload,
                        )
                        .then(async res => {
                          dispatch({
                            type: ACTIVATE_PRODUCT,
                          });
                        })
                        .catch(err => {
                          dispatch({
                            type: ACTIVATE_PRODUCT,
                          });
                          dispatch({
                            type: DIALOG_MESSAGE,
                            dialogType: DIALOG_TYPES.ERROR,
                            message: err?.response?.data?.problemPublic
                              ? err?.response?.data?.problemPublic
                              : 'Error en proceso de seguro',
                          });
                        });
                    } else if (planChubb === null) {
                      dispatch({
                        type: ACTIVATE_PRODUCT,
                      });
                    }
                  } else {
                    dispatch({
                      type: DIALOG_MESSAGE,
                      dialogType: DIALOG_TYPES.ERROR,
                      message:
                        res?.data?.problemPublic ||
                        'Ha ocurrido un error al activar producto',
                    });
                  }
                })
                .catch(err => {
                  dispatch({
                    type: DIALOG_MESSAGE,
                    dialogType: DIALOG_TYPES.ERROR,
                    message: err?.response?.data?.problemPublic
                      ? err?.response?.data?.problemPublic
                      : 'Error activando producto',
                  });
                })
                .finally(() => dispatch({type: ACTIVE_PROD_STATUS_HIDE}));
            }
          })
          .catch(err => {
            dispatch({
              type: DIALOG_MESSAGE,
              dialogType: DIALOG_TYPES.ERROR,
              message: 'Error obteniendo datos de onboarding',
            });
          });
      }
    }
  };
};

import { CreditCardOffOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Modal, TextField, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { WALLET_API } from 'src/Data/Api';
import mastercard from 'src/assets/icons/cmf_linea/mastercard.svg';
import visa from 'src/assets/icons/cmf_linea/visa.svg';
import useResponsive from 'src/hooks/useResponsive';
import { BreakPointTheme } from 'src/theme/breakpoints';
import { FONT_PRIMARY } from 'src/theme/typography';
import cardValidator from 'src/utils/cardValidator';
import validator from 'validator';
import CloseButton from '../../assets/images/cmf_linea/closeButton.svg';
import CustomStyledBox from '../Box/BoxCustom';
import ButtonBackModal from '../Button/ButtonBackModal';
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props
  return (
    <IMaskInput
      {...other}
      mask="0000 0000 0000 0000"
      placeholder="0000 0000 0000 0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({target: {name: props.name, value}})}
      overwrite
    />
  )
})
export const CreditCardPay = ({
  onClose,
  dataResult,
  amount,
  handlePreviousStep,
  OnCloseDrawer,
}) => {
  const isDesktop = useResponsive('up', 'sm')
  const {CurrentUser} = useSelector(state => state.AuthenticationReducer)
  const {card} = useSelector(
    state =>
      state.ActiveProductReducer?.listActiveProduct[0]?.customerAccountCards[0],
  )
  const [cardValidData, setCardValidData] = useState({
    type: null,
    valid: false,
    code: {
      name: '',
      size: 0,
    },
    length: 0,
  })
  const cardPayment = async payload => {
    try {
      const {data} = await axios.post(
        `${WALLET_API}api/metrobank/payment`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${CurrentUser?.tokenCMF}`,
          },
        },
      )
      return data
    } catch (error) {
      throw error
    }
  }
  const [centinelResponse, setCentinelResponse] = useState(false)

  const [injetPayload, setInjectPayload] = useState(null)
  const cardAlias = null;
  const [token3ds, setToken3ds] = useState(null)
  const [url3ds, setUrl3ds] = useState(null)
  const [cardHolder, setCardHolder] = useState(null)
  const [cardNumber, setCardNumber] = useState(null)
  const [expDate, setExpDate] = useState(null)
  const [cvvNumber, setCvvNumber] = useState(null)
  const tokenizeCard = false;
  const [payload, setPayload] = useState(null)
  const [response, setResponse] = useState(null)
  const [statusResponse, setStatusResponse] = useState(null)

  const [loading, setLoading] = useState(false)
  const formatCardNumber = cardNumber => {
    // Eliminar espacios en blanco y guiones
    cardNumber = cardNumber.replace(/\s/g, '').replace(/-/g, '')

    // Dividir el número de tarjeta en grupos de cuatro dígitos
    const groups = []
    for (let i = 0; i < cardNumber.length; i += 4) {
      groups.push(cardNumber.slice(i, i + 4))
    }

    // Unir los grupos con espacios
    return groups.join(' ')
  }
  const isValidData = () => {
    const validName =
      cardHolder === null || cardValidator.cardHolder(cardHolder)
    const validCard =
      cardNumber === null || cardValidator.cardNumber(cardNumber).valid
    const validCardType = cardNumber === null || cardValidData?.type

    const expDateSplit = expDate?.split('/')
    const currentDate = moment()

    // TODO: Refactor exp date validations
    const validateYear =
      expDateSplit === undefined ||
      (expDateSplit &&
        expDateSplit[1] &&
        Number(expDateSplit[1]) >= Number(currentDate.format('YYYY')) &&
        Number(expDateSplit[1]) <=
          Number(
            currentDate
              .clone()
              .add(5, 'years')
              .format('YYYY'),
          ))
    const validateMonth =
      expDateSplit === undefined ||
      (expDateSplit[0] &&
        Number(expDateSplit[0]) >= 1 &&
        Number(expDateSplit[0]) <= 12)
    const validExpDate =
      expDateSplit !== undefined &&
      expDateSplit[1] &&
      Number(expDateSplit[1]) === Number(currentDate.format('YYYY'))
        ? Number(expDateSplit[0]) >= currentDate.month() + 1
        : validateYear && validateMonth

    const validCVV =
      cvvNumber === null ||
      (validator.isNumeric(cvvNumber) && cvvNumber.length === 3)

    const validAlias =
      cardAlias === null ||
      (cardValidator.cardAlias(cardAlias) && cardAlias.length >= 3)

    const allow =
      !validName ||
      !validCard ||
      !validExpDate ||
      !validCVV ||
      !cardHolder ||
      !cardNumber ||
      !expDate ||
      !cvvNumber

    return {
      name: validName ? null : 'Ingresa un nombre válido',
      card: validCard && validCardType ? null : 'Verifica el número de tarjeta',
      expDate: validExpDate ? null : 'Fecha inválida',
      cvv: validCVV ? null : `${cardValidData?.code.name ?? 'CVV'} inválido`,
      alias: validAlias && tokenizeCard ? null : 'Ingresa un alias válido',
      continue: allow,
    }
  }

  const [isValid, setIsValid] = useState(true)

  const handleChangeCardName = value => {
    // Realiza la validación aquí (puedes personalizar la lógica de validación)
    if (value.trim() === '') {
      setIsValid(false)
    } else {
      setIsValid(true)
    }

    // setCardHolder(value)
    const inputText = value
    const onlyLetters = inputText.replace(/[^a-zA-Z\s]+/g, '')
    if (onlyLetters.length <= 16) {
      setCardHolder(onlyLetters)
    }
  }

  const formatExpDate = dateExp => {
    // Eliminar caracteres no numéricos
    dateExp = dateExp.replace(/\D/g, '')

    // Obtener los primeros dos dígitos (mes) y los últimos dos dígitos (año)
    const month = dateExp.slice(0, 2)
    const year = dateExp.slice(2, 6)
    // Construir la fecha formateada
    let fechaFormateada = month
    if (month.length === 2) {
      fechaFormateada += '/'
    }
    fechaFormateada += year

    return fechaFormateada
  }
  const handleChangeCardNumber = text => {
    const valid = cardValidator.cardNumber(text)
    setCardValidData(valid)
    const cleanedNumber = text.replace(/\D/g, '')
    const formatNumber = formatCardNumber(cleanedNumber)
    setCardNumber(formatNumber)
  }
  const handleApiCallError = message => {
    setCentinelResponse(false)
    setLoading(false)
    setResponse(undefined)
    NotificationManager.error(
      message ? message : 'El pago no se procesó o fue cancelado.',
    )
  }
  useEffect(() => {
    if (centinelResponse && response) {
      handleChallenge(
        payload,
        response.consumerAuthenticationInformation?.referenceId ?? '',
        response.clientReferenceInformation?.code ?? '',
      )
    }
  }, [centinelResponse])
  /// y estoy aqqui
  const handleChallenge = async (payload, referenceId, transactionId) => {
    setLoading(true)
    payload['cardHolder'] = cardHolder
    payload['amount'] = amount
    payload['userId'] = CurrentUser?.customerId
    payload['token'] = null

    payload['typePay'] = 'APE'
    payload['referenceId'] = referenceId
    payload['transactionId'] = transactionId

    try {
      const response = await cardPayment(payload)

      setStatusResponse(response?.status)
      if (
        response?.status === 'AUTHENTICATION_SUCCESSFUL' ||
        response?.status === 'AUTHORIZED'
      ) {
        let colletInfo = {
          order: response?.transaction?.orderId?.split('-') ?? [''],
          date: response?.submitTimeUtc,
          authorize: response?.vtcAuthorized,
        }
        dataResult(colletInfo)
        onClose()
      } else if (response?.status === 'PENDING_AUTHENTICATION') {
        setLoading(false)
        setToken3ds(
          response.consumerAuthenticationInformation?.accessToken ?? '',
        )
        setUrl3ds(response.consumerAuthenticationInformation?.stepUpUrl ?? '')
        setInjectPayload({
          ...payload,
          authenticationTransactionId:
            response.consumerAuthenticationInformation
              ?.authenticationTransactionId ?? '',
        })
        const url = WALLET_API
        const socket = io(url)
        socket.on(
          `${response?.consumerAuthenticationInformation
            ?.authenticationTransactionId ?? ''}`,
          function(data) {
            if (
              data?.status === 'AUTHENTICATION_SUCCESSFUL' ||
              data?.status === 'AUTHORIZED'
            ) {
              let colletInfo = {
                order: data?.transaction?.orderId?.split('-') ?? [''],
                date: data?.submitTimeUtc,
                authorize: data?.vtcAuthorized,
              }
              dataResult(colletInfo)
              onClose()
            } else {
              handleApiCallError()
            }
          },
        )
      } else {
        handleApiCallError()
      }
    } catch (error) {
      handleApiCallError()
    }
    setLoading(false)
  }
  //
  const handleChangeExpDate = text => {
    if (text?.length < expDate?.length) {
      setExpDate(text)
      return
    }
    const formatExp = formatExpDate(text)
    setExpDate(formatExp)
  }
  const handleRequestPayment = async () => {
    if (amount < 1) {
      return handleApiCallError('Ingresa un monto válido')
    }

    const splitDate = expDate.split('/')
    const payload = {
      card: card,
      cardType: cardValidData?.type === 'visa' ? '001' : '002',
      cardNumber: cardNumber.replace(/\s+/g, ''),
      expiryMonth: `${splitDate[0]}`,
      expiryYear: `${splitDate[1]}`,
      typePay: 'APS',
    }

    setLoading(true)

    try {
      setPayload(payload)
      const response = await cardPayment(payload)
      if (response) {
        if (response?.status !== 'COMPLETED') {
          handleApiCallError()
        } else {
          setResponse(response)
        }
      } else {
        handleApiCallError()
      }
    } catch (error) {
      handleApiCallError()
    }
  }

  function handleMessage(event) {

  }

  window.addEventListener('message', handleMessage, false)
  const dataResponse = useMemo(() => {
    return response?.status === 'COMPLETED' ? (
      <>
        <iframe
          title='Cardinal collection Iframe'
          id="cardinal_collection_iframe"
          name="collectionIframe"
          height="10"
          width="10"
          style={{
            display: 'none',
          }}></iframe>
        <form
          id="cardinal_collection_form"
          method="POST"
          target="collectionIframe"
          action={
            response.consumerAuthenticationInformation.deviceDataCollectionUrl
          }
          ref={ref => ref?.requestSubmit()}>
          <input
            id="cardinal_collection_form_input"
            type="hidden"
            name="JWT"
            value={
              response.consumerAuthenticationInformation.accessToken
            }></input>
        </form>
      </>
    ) : null
  }, [response])
  return (
    <CustomStyledBox
      sx={{
        display: 'flex',
        width: '384px',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexShrink: 0,
        borderRadius: '32px 0px 0px 32px',
        background: 'var(--white, #FFF)',
        [BreakPointTheme.breakpoints.down('sm')]: {
          width: '100vw',
          borderRadius: 0,
          height: 'auto',
          minHeight: '-webkit-fill-available',
        },
      }}>
      <Modal
        open={statusResponse === 'PENDING_AUTHENTICATION'}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '24px',
            boxShadow: 24,
            p: 4,
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '.5rem',
            }}>
            <div
              style={{
                display: 'flex',
                width: '28px',
                height: '28px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                borderRadius: '8px',
                border: '1.6px solid #D0D5DD',
                cursor: 'pointer',
              }}>
              <CloseIcon
                onClick={() => {
                  setStatusResponse(null)
                  setResponse(null)
                  setCentinelResponse(false)
                }}
                sx={{
                  width: '16px',
                  height: '16px',
                  flexShrink: 0,
                }}
              />
            </div>
          </div>
          {statusResponse === 'PENDING_AUTHENTICATION' && (
            <>
              <iframe title='step up iframe' name="step-up-iframe" height="600" width="100%"></iframe>
              <form
                id="step-up-form"
                method="POST"
                target="step-up-iframe"
                action={url3ds}
                ref={ref => ref?.requestSubmit()}>
                <input
                  // id="step-up-form"
                  type="hidden"
                  name="JWT"
                  value={token3ds}
                />
                <input
                  // id="step-up-form"
                  type="hidden"
                  name="MD"
                  value={JSON.stringify(injetPayload)}
                />
              </form>
            </>
          )}
        </Box>
      </Modal>
      <Box
        sx={{
          display: 'flex',
          padding: '24px 24px 16px 24px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          borderBottom: '1px solid var(--specific-light-stroke-m, #EAECF0)',
          alignSelf: 'stretch',
          fontFamily: FONT_PRIMARY,
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          letterSpacing: '-0.36px',
        }}>
        Pagar tarjeta{/*  */}
        <img
          role='button'
          alt='Close button'
          src={CloseButton}
          onClick={() => {
            OnCloseDrawer()
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          padding: '16px 24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '16px',
          alignSelf: 'stretch',
        }}>
        <ButtonBackModal
          types="Modal"
          onClick={handlePreviousStep}
          texto="Volver"
        />

        <Typography variant="subtitle4">Datos de la tarjeta</Typography>
        <Box sx={{width: '100%'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
              alignSelf: 'stretch',
            }}>
            <TextField
              fullWidth
              label="Nombre del TarjetaHabiente"
              placeholder="María Alba"
              error={!isValid}
              helperText={!isValid ? 'El nombre es obligatorio' : ''}
              multiline={false}
              numberOfLines={1}
              maxLength={16}
              keyboardType="default"
              secure={false}
              autoComplete="off"
              capitalize="none"
              autoCorrect={false}
              value={cardHolder}
              onChange={e => handleChangeCardName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Número de tarjeta"
              inputCom
              InputProps={{
                inputComponent: TextMaskCustom,
                startAdornment: cardValidData?.type ? (
                  <img
                    alt='Visa or MasterCard'
                    src={cardValidData?.type === 'visa' ? visa : mastercard}
                    style={{
                      width: '30px',
                      height: '20px',
                      flexShrink: 0,
                      fill: '#D8E3F3',
                    }}
                  />
                ) : (
                  <CreditCardOffOutlined />
                ),
              }}
              success={false}
              error={isValidData().card ?? ''}
              helperText={isValidData().card ?? ''}
              isDisable={loading}
              multiline={false}
              numberOfLines={1}
              maxLength={cardValidData ? cardValidData.length : 19}
              keyboardType="numeric"
              secure={false}
              capitalize="none"
              value={cardNumber ?? ''}
              onChange={e => handleChangeCardNumber(e.target.value)}
              onReset={() => setCardNumber(null)}
              contextMenuHidden
            />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}>
              <TextField
                style={{flex: 1, paddingRight: 8}}
                label={
                  cardValidData
                    ? `Código ${cardValidData.code.name}`
                    : 'Código CVV'
                }
                placeholder="222"
                // iconL="credit-card-lock-outline"
                iconR=""
                success={false}
                error={isValidData().cvv ?? ''}
                helperText={isValidData().cvv ?? ''}
                isDisable={loading}
                multiline={false}
                numberOfLines={1}
                maxLength={cardValidData ? cardValidData.code.size : 3}
                keyboardType="numeric"
                secure={true}
                capitalize="none"
                value={cvvNumber ?? ''}
                onChange={e =>
                  /^[0-9]{1,3}$/.test(e.target.value) || e.target.value === ''
                    ? setCvvNumber(e.target.value)
                    : null
                }
                onReset={() => setCvvNumber(null)}
              />
              <TextField
                // style={{flex: 1, paddingLeft: 8}}
                label={!isDesktop ? 'Vencimiento' : 'Fecha de Vencimiento'}
                placeholder="12/2026"
                iconR="calendar-blank"
                success={false}
                helperText={isValidData().expDate ?? ''}
                error={isValidData().expDate ?? ''}
                isDisable={loading}
                multiline={false}
                numberOfLines={1}
                maxLength={7}
                keyboardType="numeric"
                secure={false}
                capitalize="none"
                value={expDate ?? ''}
                onChange={e => handleChangeExpDate(e.target.value)}
                onReset={() => setExpDate(null)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          padding: '16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch',
          height: '50%',
          justifyContent: 'flex-end',
        }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          disabled={isValidData().continue || loading}
          sx={{
            display: 'flex',
            height: '56px',
            padding: '0px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
            borderRadius: '100px',
          }}
          onClick={() => handleRequestPayment()}>
          <Typography variant="buttonStyle">Continuar</Typography>
        </LoadingButton>
      </Box>
      {dataResponse}
    </CustomStyledBox>
  )
}
export default CreditCardPay

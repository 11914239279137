import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import StepWizard from 'react-step-wizard';
import 'src/App.css';
import ButtonFloat from 'src/components/Button/floatingButtton/whastapp';
import PrepagoProductExists from 'src/containers/OnboardingPrepago/PrepagoProductExists';
import PrepagoProductStop from 'src/containers/OnboardingPrepago/PrepagoProductStop';
import PrepagoRedirectApp from 'src/containers/OnboardingPrepago/PrepagoRedirectApp';
import PrepagoRequestInRevision from 'src/containers/OnboardingPrepago/PrepagoRequestInRevision';
import 'src/index.css';
import MaintenanceModal from '../../components/Modals/maintenanceModal';
import {
  codeReferidos,
  getBannerActive,
  getClearBannerActive,
  getToken,
} from '../../store/actions/requestCard.actions';
import ActividadLaboral from './ActividadLaboral';
import ApprovedRequest from './ApprovedRequest';
import ChubbScreen from './ChubbScreens/ChubbScreens';
import CreditApproved from './CreditApproved';
import { DataReviewAPC } from './DataReviewAPC';
import ErrorScreen from './ErrorScreen';
import IdentityMissingFields from './IdentityMissingFields';
import ProductApproved from './ProductApproved';
import ProductExist from './ProductExist';
import ProductPendingApproved from './ProductPendingApproved';
import ReadAndSingPDF from './ReadAndSingPDF';
import ReadPdfPrepago from './ReadPdfPrepago';
import Recommendation from './Recommendations';
import RequestInRevision from './RequestInRevision';
import { RequestRejected } from './RequestRejected';
import ScanID from './ScanID';
import { VerificationHasTD } from './VerificationHasTD';
import VerifyMailPhone from './VerifyMailPhone';
import Welcome from './Welcome';
import SCREENS from './screens';

const IndexCard = () => {
  const token = useSelector(state => state.RequestCardReducer.mdl03);
  const [isIdle, setIsIdle] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const searchParams = window.location.search;
    if (searchParams.length > 5 && searchParams.includes('?ref')) {
      let code = searchParams.slice(5);
      dispatch(codeReferidos(code));
    } else if (searchParams.length === 0) {
      dispatch(codeReferidos(null));
    } else {
      dispatch(codeReferidos(null));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await dispatch(getToken());
        if (!token) {
          return;
        }
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getBannerActive(token));
      dispatch(getClearBannerActive());
    }
  }, [token]);

  const handleOnIdle = () => {
    setIsIdle(true);
    window.location.reload();
  };

  const handleOnActive = () => {
    setIsIdle(false);
  };

  const handleOnAction = () => {
    setIsIdle(false);
  };

  useIdleTimer({
    timeout: 7 * 60 * 1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CMF construye tus sueños</title>
      </Helmet>
      <StepWizard
        initialStep={SCREENS.Welcome}
        isLazyMount={true}
        isHashEnabled={false}
        transitions={{
          enterRight: 'fadeIn',
          enterLeft: 'fadeIn',
          exitRight: 'fadeOut',
          exitLeft: 'fadeOut',
        }}>
        <Welcome />
        <Recommendation />
        <VerifyMailPhone />
        <ScanID canReturn={true} />
        <DataReviewAPC canReturn={false} />
        <ActividadLaboral />
        <ChubbScreen />
        <CreditApproved />
        <ReadAndSingPDF canReturn={true} />
        <ErrorScreen />
        <RequestRejected />
        <RequestInRevision />
        <ApprovedRequest />
        <VerificationHasTD />
        <ProductExist />
        <IdentityMissingFields />
        <PrepagoProductExists />
        <PrepagoRequestInRevision />
        <PrepagoRedirectApp />
        <ReadPdfPrepago />
        <PrepagoProductStop />
        <ProductApproved />
        <ProductPendingApproved />
      </StepWizard>
      <MaintenanceModal origin="TDC" />
      <ButtonFloat />
    </>
  );
};
export default IndexCard;

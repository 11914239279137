import { message } from 'antd';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { axiosInstance, axiosInstanceMdl07 } from 'src/config/axiosMiddleware';
import { LOGIN_USER_SUCCESS } from '../../constrants/reducers';
import { API_KEY, Cmf_Base, Cmf_Base02 } from '../../Data/Api';
import { openNotificationWithIcon } from '../../utils/Functions';
import { EMAIL_VALIDATION } from '../../utils/regex';

/**
 * meotodo para la autenticacion de usuarios
 * @param {*} credentials email y password del usuario
 * @param {*} history redireccion a la pagina de inicio
 * @returns
 */

export const LoginAuthentication = credentials => {
  return async dispatch => {
    dispatch({type: 'LOADING_SHOW'});
    dispatch({type: 'START_LOADING'});
    try {
      const response = await axios.post(`${Cmf_Base}api/auth/loginSF`, {
        username: credentials.email,
        password: credentials.password,
      });
      const {data} = response;
      if (!response.data.error && data) {
        const token = data.tokenCMF;
        let SelectedUser = data;
        dispatch({
          type: 'LOGIN_AUTHENTICATION_SUCCESS',
          user: SelectedUser,
          ApiKey: token,
        });
        if (data.accountSF[0].ID_CMF__c && !data.mustChangePassword) {
          dispatch({
            type: 'CUSTOMER_ID_SET',
            customerId: data.accountSF[0].ID_CMF__c,
          });
          dispatch({
            type: 'PROCCESS_LOGIN',
            payload: true,
          });
        } else if (data.accountSF[0].ID_CMF__c && data.mustChangePassword) {
          dispatch({
            type: 'CUSTOMER_ID_SET',
            customerId: data.accountSF[0].ID_CMF__c,
          });
          dispatch({
            type: 'EMAIL',
            payload: data?.userSF[0]?.Email,
          });
          dispatch({
            type: 'PROCCESS_LOGIN',
            payload: 3,
          });
        } else {
          axiosInstance
            .get(
              `api/Customers/GetCustomerbySalesforceId?salesforceId=${data.ID_CMF__c}`,
            )
            .then(response => {
              const {data} = response;
              dispatch({
                type: 'CUSTOMER_ID_SET',
                customerId: data.id,
              });

              dispatch({
                type: 'INFORMATION_BY_ID_SUCCESS',
                payload: data,
              });
              //Set active product to avoid card home crash
              dispatch({
                type: 'ACTIVEPRODUCT_SUCCESS',
                payload: data.customerProducts,
              });
            })
            .catch(err => {
              NotificationManager.error('Ha ocurrido un error');
              dispatch({
                type: 'LOGIN_AUTHENTICATION_ERROR',
                data: data,
              });
            });
        }
      } else {
        NotificationManager.error(response?.data?.message);
      }
    } catch (err) {
      NotificationManager.error('Ha ocurrido un error');
    } finally {
      dispatch({type: 'STOP_LOADING'});
      dispatch({type: 'LOADING_HIDE'});
    }
  };
};
/**
 * Metodo que permite cerrar sesion a un usuario
 * @returns
 */
export const signOut = () => {
  return async (dispatch, getState) => {
    const {AuthenticationReducer} = getState();
    const token = AuthenticationReducer?.CurrentUser?.tokenCMF;
    try {
      const response = await axiosInstance.post(
        `api/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const {status} = response;
      if (status === 200) {
        dispatch({type: 'LOGOUT_SUCCESS'});
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Ha ocurrido un error');
    }
  };
};

export const loginFactoringAction = (payloadLogin, history) => {
  return async dispatch => {
    let payload = {
      ...payloadLogin,
      type: 'cmf_factoring',
    };
    dispatch({type: 'LOAD_FACTORING_LOGIN', payload: true});
    try {
      const response = await axios.post(`${Cmf_Base}api/auth/login`, payload, {
        headers: {
          Apikey: API_KEY,
        },
      });
      const {data} = response;
      if (data) {
        const resp = await axios.get(
          `${Cmf_Base02}api/factoring/onboarding/customerNumber/${data.id}`,
          // { headers: {Apikey: API_KEY} }
        );
        dispatch({
          type: 'LOAD_DATA_LOGIN_FACTORING',
          payload: resp.data.JsonAnswer,
        });

        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: resp.data.JsonAnswer,
        });
      }
      history.push('/Home/Factoring');
    } catch (error) {
      openNotificationWithIcon(
        'error',
        'Datos de acceso incorrectos. Por favor intentalo de nuevo.',
      );
    } finally {
      dispatch({type: 'LOAD_FACTORING_LOGIN', payload: false});
    }
  };
};

export const ResetPasswordSendEmail = (email, onSuccess) => {
  return async dispatch => {
    dispatch({type: 'RESETPASSWORD_STATUS_SHOW'});
    try {
      const response = await axiosInstanceMdl07.post(
        `v1/api/user/forgot-password/request-otp`,
        {
          email: email,
        },
      );
      const {data} = response;
      dispatch({type: 'RESETPASSWORD_STATUS_SUCCESS', payload: data});
      if (data?.logger?.status === 201) {
        if (data?.data?.message) {
          onSuccess(false);
          NotificationManager.error(data?.data?.message);
        } else {
          onSuccess(true);
          NotificationManager.success(
            'Ingresa el código enviado por SMS a tu número de celular',
          );
        }
      }
    } catch (error) {
      onSuccess(false);
      if (error?.response?.status === 400) {
        NotificationManager.error(
          'Límite de intentos alcanzado. Intente más tarde.',
        );
      }
      dispatch({type: 'RESETPASSWORD_STATUS_ERROR', payload: error});
    } finally {
      dispatch({type: 'RESETPASSWORD_STATUS_HIDE'});
    }
  };
};

export const ResetPasswordSendEmailConfig = credential => {
  return async dispatch => {
    if (EMAIL_VALIDATION.test(credential.Email)) {
      dispatch({type: 'RESETPASSWORD_STATUS_SHOW'});
      try {
        const response = await axiosInstanceMdl07.post(
          `v1/api/user/forgot-password/request-otp`,
          {
            email: credential.Email,
          },
        );
        const {data} = response;

        dispatch({type: 'RESETPASSWORD_STATUS_SUCCESS', payload: data});
        if (data?.logger?.status === 201) {
          if (data?.data?.message) {
            message.error(data?.data?.message);
          } else {
            message.success(
              'Ingresa el código enviado por SMS a tu número de celular',
            );
          }
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          message.error('Límite de intentos alcanzado. Intente más tarde.');
        }
        dispatch({type: 'RESETPASSWORD_STATUS_ERROR', payload: error});
      } finally {
        dispatch({type: 'RESETPASSWORD_STATUS_HIDE'});
      }
    } else {
      message.warning('Es requerido llenar el Campo de Email');
    }
  };
};

export const ResetPasswordValidateCode = (credential, onNextPassword) => {
  return async dispatch => {
    dispatch({type: 'RESETPASSWORD_STATUS_SHOW'});
    try {
      const response = await axiosInstanceMdl07.post(
        `v1/api/user/forgot-password/confirm-otp`,
        {
          to: credential.to,
          code: credential.code,
        },
      );
      const {data} = response;
      dispatch({type: 'RESETPASSWORD_STATUS_SUCCESS', payload: data});
      if (data?.logger?.status === 201) {
        onNextPassword(true);
        NotificationManager.success('Código validado correctamente');
      }
    } catch (error) {
      onNextPassword(false);
      if (
        error?.response?.data?.data?.problemPublic ===
        'Max check attempts reached'
      ) {
        NotificationManager.error(
          'Límite de intentos alcanzado. Intente más tarde.',
        );
        return;
      }
      NotificationManager.error('El código ingresado es incorrecto');
      dispatch({type: 'RESETPASSWORD_STATUS_ERROR', payload: error});
    } finally {
      onNextPassword(false);
      dispatch({type: 'RESETPASSWORD_STATUS_HIDE'});
    }
  };
};

export const ResetPasswordValidateCodeConfig = credential => {
  return async dispatch => {
    if (credential.code === '') {
      message.warning('Es requerido llenar el Campo de Código');
      return;
    }

    dispatch({type: 'RESETPASSWORD_STATUS_SHOW'});
    try {
      const response = await axiosInstanceMdl07.post(
        `v1/api/user/forgot-password/confirm-otp`,
        {
          to: credential.to,
          code: credential.code,
        },
      );
      const {data} = response;
      dispatch({type: 'RESETPASSWORD_STATUS_SUCCESS', payload: data});
      if (data?.logger?.status === 201) {
        message.success('Código validado correctamente');
      }
    } catch (error) {
      if (
        error?.response?.data?.data?.problemPublic ===
        'Max check attempts reached'
      ) {
        message.error('Límite de intentos alcanzado. Intente más tarde.');
        return;
      }
      message.error('El código ingresado es incorrecto');
      dispatch({type: 'RESETPASSWORD_STATUS_ERROR', payload: error});
    } finally {
      dispatch({type: 'RESETPASSWORD_STATUS_HIDE'});
    }
  };
};

export const ResetPasswordChangePassword = (credential,code, onSuccess) => {
  return async dispatch => {
    dispatch({type: 'RESETPASSWORD_STATUS_SHOW'});
    try {
      const response = await axiosInstanceMdl07.patch(
        `v1/api/user/forgot-password/change-password`,
        {
          email: credential.to,
          newPassword: credential.password,
          code:code
        },
      );
      const {data} = response;
      dispatch({type: 'RESETPASSWORD_STATUS_SUCCESS', payload: data});
      if (data?.logger?.status === 200 && data?.logger?.message !== '') {
        onSuccess(true);
        NotificationManager.success('Contraseña cambiada exitosamente');
      } else {
        onSuccess(false);
        NotificationManager.error(
          'Ha ocurrido un error al cambiar la contraseña. Intente nuevamente',
        );
      }
    } catch (error) {
      onSuccess(false);
      NotificationManager.error(
        'Ha ocurrido un error al cambiar la contraseña. Intente nuevamente',
      );
      dispatch({type: 'RESETPASSWORD_STATUS_ERROR', payload: error});
    } finally {
      dispatch({type: 'RESETPASSWORD_STATUS_HIDE'});
      onSuccess(false);
    }
  };
};

export const changePassword = (customerId, password, tokenCMF, onSuccess) => {
  return async () => {
    try {
      const response = await axiosInstanceMdl07.patch(
        `v1/api/user/changePassword`,
        {
          userId: customerId,
          newPassword: password,
        },
      );
      if (response.data.data) {
        onSuccess(true);
        NotificationManager.success(
          'La contraseña ha sido actualizada con éxito.',
        );
      }
    } catch (error) {
      NotificationManager.error(
        'Se ha producido un error al intentar cambiar la contraseña',
      );
    }
  };
};

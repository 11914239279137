import produce from 'immer';
import moment from 'moment';
import {
  ACTIVATE_ONBOARDING,
  ACTIVATE_PRODUCT,
  ACTIVE_PROD_STATUS_HIDE,
  ACTIVE_PROD_STATUS_SHOW,
  ADD_IDENTITY_BATCH,
  ADD_IDENTITY_EID_SUCCESS,
  CLEAR_PDF,
  DATA_MATRIZ_SIGN,
  DIALOG_MESSAGE,
  DOCUMENT_UPLOADED_SUCCESS,
  EDIT_REQUEST_CARD_FORM,
  FORM_REQUEST_SUCCESS,
  FORM_REQUEST_UPDATE,
  GET_ACTIVE_BANNER,
  GET_ACTIVITY_TYPES,
  GET_CLEAN_BANNER,
  GET_CLIENTE_DATA,
  GET_COUNTRIES,
  GET_COUNTRIES_BIRTH,
  GET_COUNTRIES_BIRTH_TRANSLATE,
  GET_CUSTOMERT_ID,
  GET_DISTRICTS,
  GET_GENDERS,
  GET_LOAD_TO_PDF,
  GET_LOAD_TO_PDF_HIDE,
  GET_MARITAL_STATUS,
  GET_MAX_LOAN_LIMIT,
  GET_NATIONALITIES,
  GET_NATIONALITIES_TRANSLATE,
  GET_OCCUPATIONS,
  GET_PDF_TO_SIGN_SUCCESS,
  GET_PROFESSIONS,
  GET_PROVINCES,
  GET_REQUEST_ACCESS,
  GET_SIGNID_DATA,
  GET_STATUS_ONBOARDING,
  GET_TERMS_SUCCESS,
  GET_TOWNSHIPS,
  GET_TYPE_OF_IDENTITY,
  GET_TYPE_OF_IMAGE,
  GET_TYPE_OF_PHONE,
  GET_VALIDATE_MINIMAL_AGE,
  ORIGEN_SIGN_ID,
  RESET_REQUEST_CARD_FORM,
  RESET_STATE_SCREEN,
  SEND_EMAIL,
  SET_DATA_CLEAN,
  SET_LOAD_SIGN,
  SET_REQUEST_CARD_LOADING,
  SET_REQUEST_VIDEO_ID,
  UPDATE_SIGNATURE_REQUEST,
} from '../../constrants/reducers';

const initialState = {
  form: {},
  professions: [],
  occupations: [],
  nationalities: [],
  nationalitiesTranslate: [],
  countriesBirthTranslate: [],
  countries: [],
  provinces: [],
  districts: [],
  townships: [],
  activityTypes: [],
  genders: [],
  maritalStatus: [],
  loading: {
    emailField: true,
    cellphoneField: true,
    uploadDocument: false,
    direccionesField: true,
  },
  OriginSign: null,
  settings: {
    minimalAge: 0,
    identityTypes: [],
    typePhones: [],
    imageTypes: [],
    requestStatus: [],
  },
  documentBase64: null,
  requestVideoId: null,
  documentId: null,
  identityId: null,
  singResult: null,
  dialog: {},
  onboardingId: null, //650
  productId: null,
  customerId: null,
  statusOnboarding: null,
  signatureRequest: null,
  onboardingSaved: {},
  onboardingGet: {},
  productIsActive: false,
  isEmailSended: false,
  singIdData: null,
  customerProductId: null,
  loadToPdf: false,
  activePro: {},
  activePros: [],
  LoadingactivePro: false,
  activeProError: false,
  origin: null,
  originSourceOnboarding: null,
  tdc_flow: 'true',
  preap_flow: 'true',
  originSign: null,
  matrizD: {},
  ciudadName: {},
  finishLoad: true,
  branchOfficeId: {},
  cedulaState: {},
  routeCreateUser: {},
  limite: {},
  statusOnboarindSucces: null,
  approvedDate: null,
  referido: null,
  personalData: {},
  signatureContract: null,
  signCapture: false,
  linkImgSign: null,
  banner: [],
  TypeProductId: null,
  verifyRequirement: null,
  maxLoanLimit: null,
  configGlobal: null,
  maxDay: null,
  checkTe: null,
  base64: null,
  ipAddress: null,
  emailForm: null,
  phoneForm: null,
  seonState: null,
  checkSeon: null,
  resultTE: null,
  levelRisk: null,
  loadingSms: false,
  errorPhone: false,
  loadingEmail: false,
  errorMail: false,
  btnWts: false,
  mapeo_nacionalidades: [],
  onboardingPrepago: false,
  activityTypeForm: null,
  provinceForm: null,
  civilStatusForm: null,
  occupationForm: null,
  salaryForm: null,
  StatusMotorCreditoV2: {},
  valueMotorCreditoV2: null,
  stopPrepaid: false,
  rejectedLexisNexisPrepaid: false,
  isLexisNexisList: false,
  loadToPdfTerms: false,
  costumerIdOnboarding: null,
  showInfoTextTDC: false,
  mdl03: null,
};

const requestCard = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ADD_IDENTITY_BATCH:
        draft.preBatchId = action.preBatchId;
        draft.origin = action.origin;
        draft.limite = action.lmCredit;
        break;
      case 'CLEAR_ORIGIN':
        draft.origin = null;
        break;
      case GET_REQUEST_ACCESS:
        draft.tdc_flow = action.payload;
        draft.dateId = action.dateId;
        draft.branchOfficeId = action.branchOfficeId;
        // draft.preap_flow = action.payload
        break;
      case 'CLEAR_PROFESSIONAL_FORM':
        draft.form = {
          ...draft.form,
          professionId: null,
          occupationId: null,
          address: null,
          companyName: null,
          workPhone: null,
          dateAdmission: null,
          addressCompany: null,
          company: null,
          companyDateAdmissionMonth: null,
          companyDateAdmissionYear: null,
          companyPhone: null,
          occupation: null,
          profession: null,
        };
        break;
      case EDIT_REQUEST_CARD_FORM:
        draft.form = {...draft.form, ...action.payload};
        break;
      case ORIGEN_SIGN_ID:
        draft.OriginSign = action.payload;
        break;
      //Resetea todo los datos almacenados en el redux de RequestCardReducer
      case RESET_REQUEST_CARD_FORM:
        draft.originSign = {};
        draft.TypeProductId = null;
        draft.linkImgSign = null;
        draft.cedulaState = {};
        draft.LoadingactivePro = false;
        draft.form = {};
        draft.finishLoad = {};
        draft.productIsActive = null;
        draft.preBatchId = null;
        draft.documentBase64 = null;
        draft.requestVideoId = null;
        draft.documentId = null;
        draft.identityId = null;
        draft.singResult = null;
        draft.activityTypes = null;
        draft.genders = null;
        draft.maritalStatus = null;
        draft.professions = null;
        draft.occupations = null;
        draft.nationalities = null;
        draft.countries = null;
        draft.provinces = null;
        draft.districts = null;
        draft.townships = null;
        draft.settings = {
          minimalAge: 0,
          identityTypes: [],
          typePhones: [],
          imageTypes: [],
          requestStatus: [],
        };
        draft.onboardingId = null;
        draft.customerId = null;
        draft.productId = null;
        draft.signatureRequest = null;
        draft.statusOnboarding = null;
        draft.onboardingSaved = null;
        draft.productIsActive = false;
        break;
      case GET_VALIDATE_MINIMAL_AGE:
        draft.settings.minimalAge = action.payload;
        break;
      case GET_TYPE_OF_IDENTITY:
        draft.settings.identityTypes = action.payload;
        break;
      case GET_TYPE_OF_PHONE:
        draft.settings.typePhones = action.payload;
        break;
      case GET_TYPE_OF_IMAGE:
        draft.settings.imageTypes = action.payload;
        break;
      case GET_STATUS_ONBOARDING:
        draft.settings.requestStatus = action.payload;
        break;
      case GET_PROFESSIONS:
        draft.professions = action.payload;
        break;
      case GET_OCCUPATIONS:
        draft.occupations = action.payload;
        break;
      case GET_NATIONALITIES:
        draft.nationalities = action.payload;
        break;
      case GET_NATIONALITIES_TRANSLATE:
        draft.nationalitiesTranslate = action.payload;
        break;
      case GET_COUNTRIES_BIRTH:
        draft.countriesBirth = action.payload;
        break;
      case GET_COUNTRIES_BIRTH_TRANSLATE:
        draft.countriesBirthTranslate = action.payload;
        break;
      case GET_COUNTRIES:
        draft.countries = action.payload;
        break;
      case GET_PROVINCES:
        draft.provinces = action.payload;
        break;
      case GET_DISTRICTS:
        draft.districts = action.payload;
        break;
      case GET_TOWNSHIPS:
        draft.townships = action.payload;
        break;
      case 'FINISH_TOWN':
        draft.finishLoad = false;
        break;
      case 'CLEAN_FINISH_TOWN':
        draft.finishLoad = true;
        break;
      case GET_ACTIVITY_TYPES:
        draft.activityTypes = action.payload;
        break;
      case GET_GENDERS:
        draft.genders = action.payload;
        break;
      case GET_MARITAL_STATUS:
        draft.maritalStatus = action.payload;
        break;
      case SET_REQUEST_CARD_LOADING:
        draft.loading = {...draft.loading, ...action.payload};
        break;
      case GET_LOAD_TO_PDF:
        draft.loadToPdf = true;
        break;
      case GET_PDF_TO_SIGN_SUCCESS:
        draft.documentBase64 = action.payload;
        break;
      case GET_LOAD_TO_PDF_HIDE:
        draft.loadToPdf = false;
        break;
      case GET_TERMS_SUCCESS:
        draft.base64Terms = action.payload;
        break;
      case SET_REQUEST_VIDEO_ID:
        draft.requestVideoId = action.requestVideoId;
        break;
      case DOCUMENT_UPLOADED_SUCCESS:
        draft.documentId = action.documentId;
        break;
      case ADD_IDENTITY_EID_SUCCESS:
        draft.identityId = action.identityId;
        break;
      case 'TYPE_PRODUCT':
        draft.TypeProductId = action.TypeProductId;
        break;
      case UPDATE_SIGNATURE_REQUEST:
        draft.signatureRequest = action.payload;
        break;
      case DIALOG_MESSAGE:
        draft.dialog = {
          title: action.title,
          dialogType: action.dialogType,
          message: action.message,
          id: moment().format('x'),
        };
        break;
      case FORM_REQUEST_SUCCESS:
        // draft.onboardingId = action.onboardingId;
        draft.customerId = action.customerId;
        draft.productId = action.productId;
        draft.statusOnboarding = action.statusOnboarding;
        draft.onboardingSaved = action.onboardingSaved;
        break;
      case FORM_REQUEST_UPDATE:
        draft.onboardingSaved = action.onboardingSaved;
        break;
      case ACTIVATE_PRODUCT:
        draft.productIsActive = true;
        break;
      case ACTIVATE_ONBOARDING:
        draft.customerId = action.customerId;
        draft.productId = action.productId;
        draft.statusOnboarding = action.statusOnboarding;
        break;
      case SEND_EMAIL:
        draft.isEmailSended = true;
        break;
      case CLEAR_PDF:
        draft.documentBase64 = null;
        break;
      case GET_CLIENTE_DATA:
        draft.onboardingGet = action.payload;
        draft.originSign = action.originSign;
        break;
      case DATA_MATRIZ_SIGN:
        draft.matrizD = action.payload;
        break;
      case GET_SIGNID_DATA:
        draft.singIdData = action.singIdData;
        break;
      case GET_CUSTOMERT_ID:
        draft.customerProductId = action.customerProductId;
        break;
      case 'ACTIVE_PROD_STATUS_SUCCESS':
        draft.activePro = action.payload;
        break;
      case 'ACTIVE_PROD_STATUS_ERROR':
        draft.activeProError = action.payload;
        break;
      case ACTIVE_PROD_STATUS_SHOW:
        draft.LoadingactivePro = true;
        break;
      case ACTIVE_PROD_STATUS_HIDE:
        draft.LoadingactivePro = false;
        break;
      case 'COUNTRY_ID':
        draft.ciudadName = action.payload;
        break;
      case 'CEDULA_NUMBER':
        draft.cedulaState = action.payload;
        break;
      case 'CREATE_CRE_PRE':
        draft.routeCreateUser = action.payload;
        break;
      case 'GET_NEW_CODE':
        draft.onboardingId = action.payload;
        break;
      case 'IN_PROCESS_ONBOARDING':
        draft.statusOnboarindSucces = action.setStatus;
        break;
      case 'ACTIVE_ONBOARDING_SUCCES':
        draft.statusOnboarindSucces = action.setStatus;
        break;
      case 'DATA_CREATED':
        draft.approvedDate = action.payload;
        break;
      case 'SET_VARIABLE_REFERIDO':
        draft.referido = action.payload;
        break;
      case 'SET_PERSONAL_DATA':
        draft.personalData = action.payload;
        break;
      case 'SET_SIGNATURE_CONTRACT':
        draft.signatureContract = action.payload;
        break;
      case SET_LOAD_SIGN:
        draft.signCapture = action.payload;
        break;
      case 'SET_DATA_IMG':
        draft.linkImgSign = action.payload;
        break;
      case 'CLEAN_DIALOG':
        draft.dialog = {};
        break;
      case 'REQUIREMENTS':
        draft.verifyRequirement = action.payload;
        break;
      case GET_ACTIVE_BANNER:
        draft.banner = action.payload;
        break;
      case SET_DATA_CLEAN:
        draft.signCapture = false;
        draft.linkImgSign = null;
        break;
      case RESET_STATE_SCREEN:
        draft.OriginSign = {};
        draft.base64Terms = null;
        draft.documentBase64 = null;
        draft.documentId = {};
        draft.matrizD = {};
        draft.onboardingGet = {};
        draft.singIdData = null;
        draft.statusOnboarindSucces = null;
        break;
      case GET_CLEAN_BANNER:
        draft.banner = action.payload;
        break;
      case GET_MAX_LOAN_LIMIT:
        draft.maxLoanLimit = action.payload;
        break;
      case 'GET_CONFIG_ONBOARD':
        draft.configGlobal = action.payload;
        break;
      case 'MAX_DATE':
        draft.maxDay = action.payload;
        break;
      case 'GLOB_ETRIBUNAL':
        draft.checkTe = action.payload;
        break;
      case 'SET_BASE_64':
        draft.base64 = action.payload;
        break;
      case 'IP_ADDRESS':
        draft.ipAddress = action.payload;
        break;
      case 'EMAIL':
        draft.emailForm = action.payload;
        break;
      case 'PHONE':
        draft.phoneForm = action.payload;
        break;
      case 'GLOB_SEON':
        draft.checkSeon = action.payload;
        break;
      case 'SEON_APROVEE':
        draft.seonState = action.payload;
        break;
      case 'SEON_REVIEW':
        draft.seonState = action.payload;
        break;
      case 'SEON_REJECTED':
        draft.seonState = action.payload;
        break;
      case 'RESULT_TE':
        draft.resultTE = action.payload;
        break;
      case 'GET_RISK_LEVEL':
        draft.levelRisk = action.payload;
        break;
      case 'SET_LOADING_SMS':
        draft.loadingSms = action.payload;
        break;
      case 'SET_ERROR_PHONE':
        draft.errorPhone = action.payload;
        break;
      case 'SET_LOADING_EMAIL':
        draft.loadingEmail = action.payload;
        break;
      case 'SET_ERROR_MAIL':
        draft.errorMail = action.payload;
        break;
      case 'GLOB_WHATSAPP':
        draft.btnWts = action.payload;
        break;
      case 'COMPLETE_MAP_COUNTRIES':
        draft.mapeo_nacionalidades = action.payload;
        break;
      case 'ONBOARDING_PREPAGO':
        draft.onboardingPrepago = action.payload;
        break;
      case 'SET_ORIGIN_ONBOARDING':
        draft.originSourceOnboarding = action.payload;
        break;
      case 'SET_ACTIVITYTYPE_FORM':
        draft.activityTypeForm = action.payload;
        break;
      case 'SET_PROVINCE_FORM':
        draft.provinceForm = action.payload;
        break;
      case 'SET_CIVILSTATUS_FORM':
        draft.civilStatusForm = action.payload;
        break;
      case 'SET_OCCUPATION_FORM':
        draft.occupationForm = action.payload;
        break;
      case 'SET_SALARY_FORM':
        draft.salaryForm = action.payload;
        break;
      case 'GET_STATUS_SWITCH':
        draft.StatusMotorCreditoV2 = action.payload;
        break;
      case 'GET_STATUS_VALUE_SWITCH':
        draft.valueMotorCreditoV2 = action.payload;
        break;
      case 'STOP_PREPAID':
        draft.stopPrepaid = action.payload;
        break;
      case 'REJECTED_LEXIS_NEXIS_PREPAID':
        draft.rejectedLexisNexisPrepaid = action.payload;
        break;
      case 'LEXIS_NEXIS_RESULT_PREPAID':
        draft.isLexisNexisList = action.payload;
        break;
      case 'GET_LOAD_TO_PDF_TERMS':
        draft.loadToPdfTerms = action.payload;
        break;
      case 'CUSTOMER_ID_ONBOARDING':
        draft.costumerIdOnboarding = action.payload;
        break;
      case 'SHOW_INFO_TEXT_TDC':
        draft.showInfoTextTDC = action.payload;
        break;
      case 'SAVE_MDL03':
        draft.mdl03 = action.payload;
        break;
      default:
        return state;
    }
  });

export default requestCard;
